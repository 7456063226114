@font-face {
  font-family: ToyotaDisplay;
  src: url(../../fonts/ToyotaDisplay_Regular.ttf);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: ToyotaDisplay;
  src: url(../../fonts/ToyotaDisplay_Bold.ttf);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: ToyotaText;
  src: url(../../fonts/ToyotaText_Regular.ttf);
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: ToyotaText;
  src: url(../../fonts/ToyotaText_Bold.ttf);
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: ToyotaText;
  src: url(../../fonts/ToyotaText_Italic.ttf);
  font-weight: 400;
  font-style: italic; }

@font-face {
  font-family: ToyotaText;
  src: url(../../fonts/ToyotaText_Bold-Italic.ttf);
  font-weight: 700;
  font-style: italic; }

body {
  font-size: 14px;
  line-height: normal;
  font-family: 'ToyotaText', sans-serif;
  position: relative; }

h1, .h1 {
  font-family: 'ToyotaText', sans-serif;
  font-size: 40px;
  font-weight: normal;
  line-height: normal;
  color: #4a4a4a; }

h2, .h2 {
  font-family: 'ToyotaDisplay', sans-serif;
  font-size: 30px;
  font-weight: normal;
  line-height: normal;
  color: #222222; }
  @media (max-width: 1024px) {
    h2, .h2 {
      font-size: 22px; } }

h3, .h3 {
  font-family: 'ToyotaDisplay', sans-serif;
  font-size: 26px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 2px;
  color: #222222; }

h4, .h4 {
  font-family: 'ToyotaDisplay', sans-serif;
  font-size: 21px;
  font-weight: normal;
  line-height: normal;
  color: #1a1a1a; }
  @media (max-width: 991px) {
    h4, .h4 {
      font-size: 18px; } }
  @media (max-width: 767px) {
    h4, .h4 {
      font-size: 14px; } }

h5, .h5 {
  font-family: 'ToyotaText', sans-serif;
  font-size: 20px;
  font-weight: normal;
  line-height: normal;
  color: #222222; }

h6, .h6 {
  font-family: 'ToyotaText', sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: normal;
  color: #222222; }

@media (max-width: 767px) {
  h3, .h3 {
    font-size: 18px;
    font-weight: normal;
    letter-spacing: 1.4px;
    color: #222222; } }

.m-t-0 {
  margin-top: 0px !important; }

.p-t-0 {
  padding-top: 0px !important; }

.m-b-0 {
  margin-bottom: 0px !important; }

.p-b-0 {
  padding-bottom: 0px !important; }

.m-l-0 {
  margin-left: 0px !important; }

.p-l-0 {
  padding-left: 0px !important; }

.m-r-0 {
  margin-right: 0px !important; }

.p-r-0 {
  padding-right: 0px !important; }

.m-t-5 {
  margin-top: 5px !important; }

.p-t-5 {
  padding-top: 5px !important; }

.m-b-5 {
  margin-bottom: 5px !important; }

.p-b-5 {
  padding-bottom: 5px !important; }

.m-l-5 {
  margin-left: 5px !important; }

.p-l-5 {
  padding-left: 5px !important; }

.m-r-5 {
  margin-right: 5px !important; }

.p-r-5 {
  padding-right: 5px !important; }

.m-t-10 {
  margin-top: 10px !important; }

.p-t-10 {
  padding-top: 10px !important; }

.m-b-10 {
  margin-bottom: 10px !important; }

.p-b-10 {
  padding-bottom: 10px !important; }

.m-l-10 {
  margin-left: 10px !important; }

.p-l-10 {
  padding-left: 10px !important; }

.m-r-10 {
  margin-right: 10px !important; }

.p-r-10 {
  padding-right: 10px !important; }

.m-t-15 {
  margin-top: 15px !important; }

.p-t-15 {
  padding-top: 15px !important; }

.m-b-15 {
  margin-bottom: 15px !important; }

.p-b-15 {
  padding-bottom: 15px !important; }

.m-l-15 {
  margin-left: 15px !important; }

.p-l-15 {
  padding-left: 15px !important; }

.m-r-15 {
  margin-right: 15px !important; }

.p-r-15 {
  padding-right: 15px !important; }

.m-t-20 {
  margin-top: 20px !important; }

.p-t-20 {
  padding-top: 20px !important; }

.m-b-20 {
  margin-bottom: 20px !important; }

.p-b-20 {
  padding-bottom: 20px !important; }

.m-l-20 {
  margin-left: 20px !important; }

.p-l-20 {
  padding-left: 20px !important; }

.m-r-20 {
  margin-right: 20px !important; }

.p-r-20 {
  padding-right: 20px !important; }

.m-t-25 {
  margin-top: 25px !important; }

.p-t-25 {
  padding-top: 25px !important; }

.m-b-25 {
  margin-bottom: 25px !important; }

.p-b-25 {
  padding-bottom: 25px !important; }

.m-l-25 {
  margin-left: 25px !important; }

.p-l-25 {
  padding-left: 25px !important; }

.m-r-25 {
  margin-right: 25px !important; }

.p-r-25 {
  padding-right: 25px !important; }

.m-t-30 {
  margin-top: 30px !important; }

.p-t-30 {
  padding-top: 30px !important; }

.m-b-30 {
  margin-bottom: 30px !important; }

.p-b-30 {
  padding-bottom: 30px !important; }

.m-l-30 {
  margin-left: 30px !important; }

.p-l-30 {
  padding-left: 30px !important; }

.m-r-30 {
  margin-right: 30px !important; }

.p-r-30 {
  padding-right: 30px !important; }

.m-t-35 {
  margin-top: 35px !important; }

.p-t-35 {
  padding-top: 35px !important; }

.m-b-35 {
  margin-bottom: 35px !important; }

.p-b-35 {
  padding-bottom: 35px !important; }

.m-l-35 {
  margin-left: 35px !important; }

.p-l-35 {
  padding-left: 35px !important; }

.m-r-35 {
  margin-right: 35px !important; }

.p-r-35 {
  padding-right: 35px !important; }

.m-t-40 {
  margin-top: 40px !important; }

.p-t-40 {
  padding-top: 40px !important; }

.m-b-40 {
  margin-bottom: 40px !important; }

.p-b-40 {
  padding-bottom: 40px !important; }

.m-l-40 {
  margin-left: 40px !important; }

.p-l-40 {
  padding-left: 40px !important; }

.m-r-40 {
  margin-right: 40px !important; }

.p-r-40 {
  padding-right: 40px !important; }

.m-t-45 {
  margin-top: 45px !important; }

.p-t-45 {
  padding-top: 45px !important; }

.m-b-45 {
  margin-bottom: 45px !important; }

.p-b-45 {
  padding-bottom: 45px !important; }

.m-l-45 {
  margin-left: 45px !important; }

.p-l-45 {
  padding-left: 45px !important; }

.m-r-45 {
  margin-right: 45px !important; }

.p-r-45 {
  padding-right: 45px !important; }

.m-t-50 {
  margin-top: 50px !important; }

.p-t-50 {
  padding-top: 50px !important; }

.m-b-50 {
  margin-bottom: 50px !important; }

.p-b-50 {
  padding-bottom: 50px !important; }

.m-l-50 {
  margin-left: 50px !important; }

.p-l-50 {
  padding-left: 50px !important; }

.m-r-50 {
  margin-right: 50px !important; }

.p-r-50 {
  padding-right: 50px !important; }

.m-t-75 {
  margin-top: 75px !important; }

.p-t-75 {
  padding-top: 75px !important; }

.m-b-75 {
  margin-bottom: 75px !important; }

.p-b-75 {
  padding-bottom: 75px !important; }

.m-l-75 {
  margin-left: 75px !important; }

.p-l-75 {
  padding-left: 75px !important; }

.m-r-75 {
  margin-right: 75px !important; }

.p-r-75 {
  padding-right: 75px !important; }

.m-t-100 {
  margin-top: 100px !important; }

.p-t-100 {
  padding-top: 100px !important; }

.m-b-100 {
  margin-bottom: 100px !important; }

.p-b-100 {
  padding-bottom: 100px !important; }

.m-l-100 {
  margin-left: 100px !important; }

.p-l-100 {
  padding-left: 100px !important; }

.m-r-100 {
  margin-right: 100px !important; }

.p-r-100 {
  padding-right: 100px !important; }

.text-red {
  color: #eb0a1e; }

.text-light-gray {
  color: #888888; }

.text-gray {
  color: #595959; }

.text-dark-gray {
  color: #4a4a4a; }

.text-black {
  color: #000000; }

.text-11 {
  font-size: 11px; }

.text-12 {
  font-size: 12px; }

.text-small {
  font-size: 12px; }

.text-medium {
  font-size: 14px; }

.text-big {
  font-size: 16px; }

.text-f18 {
  font-size: 18px; }

.text-bold {
  font-weight: bold; }

.text-italic {
  font-style: italic; }

.text-uppercase {
  text-transform: uppercase; }

.relative {
  position: relative; }

.textarea-noresize {
  resize: none; }

.hide {
  display: none !important; }

@media (max-width: 575px) {
  .d-xsm-block {
    display: block !important; } }

.mar-tn-10 {
  margin-top: -10px; }
  @media (max-width: 767px) {
    .mar-tn-10 {
      margin-top: 0; } }

.m-only {
  display: none; }
  @media (max-width: 767px) {
    .m-only {
      display: inline-block; } }

.d-only {
  display: none; }
  @media (min-width: 768px) {
    .d-only {
      display: inline-block; } }

.small-devices-only {
  display: none; }
  @media (max-width: 1024px) {
    .small-devices-only {
      display: block; } }

@media (max-width: 1024px) {
  .desktop-only {
    display: none; } }

@media (max-width: 767px) {
  .text-m-left {
    text-align: left !important; } }

.disabled-event {
  pointer-events: none; }

.alert-center {
  margin-left: auto !important;
  margin-right: auto !important; }

.word-break {
  word-break: break-all; }

.cursor-event {
  cursor: pointer; }

.placeholder-light::-webkit-input-placeholder {
  color: rgba(128, 128, 128, 0.5);
  /* Chrome/Opera/Safari */ }

.placeholder-light::-moz-placeholder {
  color: rgba(128, 128, 128, 0.5);
  /* Firefox 19+ */ }

.placeholder-light:-ms-input-placeholder {
  color: rgba(128, 128, 128, 0.5);
  /* IE 10+ */ }

.loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999999;
  height: 100%;
  background: rgba(255, 255, 255, 0.2); }

.loaderModal {
  /*position: absolute;*/
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999999;
  background: rgba(255, 255, 255, 0.2); }

.load-bar-modal {
  position: relative;
  /* margin-top: 70px;*/
  width: 100%;
  height: 4px;
  background-color: #fdba2c; }

.load-bar {
  position: fixed;
  margin-top: 70px;
  width: 100%;
  height: 4px;
  background-color: #fdba2c;
  top: 0; }
  @media (max-width: 1024px) {
    .load-bar {
      margin-top: 63px; } }

.bar {
  content: "";
  display: inline;
  position: absolute;
  width: 0;
  height: 100%;
  left: 50%;
  text-align: center; }

.bar:nth-child(1) {
  background-color: #d70417;
  animation: loading 3s linear infinite; }

.bar:nth-child(2) {
  background-color: #ff152a;
  animation: loading 3s linear 1s infinite; }

.bar:nth-child(3) {
  background-color: #ff606e;
  animation: loading 3s linear 2s infinite; }

@keyframes loading {
  from {
    left: 50%;
    width: 0;
    z-index: 100; }
  33.3333% {
    left: 0;
    width: 100%;
    z-index: 10; }
  to {
    left: 0;
    width: 100%; } }

/* Spinner Loader */
/* Add position: relative; to the parent element */
.lds-ring-loader {
  display: inline-block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99; }
  .lds-ring-loader.show {
    display: block; }
  .lds-ring-loader.hide {
    display: none; }
  .lds-ring-loader.for-comment {
    top: 250px; }

.lds-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.1); }

.lds-ring {
  box-sizing: border-box;
  display: block;
  position: absolute;
  z-index: 2;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: calc(50% - 20px);
  width: 40px;
  height: 40px;
  border: 4px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent; }

.lds-ring:nth-child(1) {
  animation-delay: -0.45s; }

.lds-ring:nth-child(2) {
  animation-delay: -0.3s; }

.lds-ring:nth-child(3) {
  animation-delay: -0.15s; }

@keyframes lds-ring {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.btn {
  -webkit-border-radius: 26px;
  -moz-border-radius: 26px;
  -ms-border-radius: 26px;
  border-radius: 26px;
  transition: all 0.3s ease;
  font-family: 'ToyotaText', sans-serif;
  font-size: 14px;
  line-height: normal;
  padding: 10px 20px; }
  .btn.focus, .btn:focus {
    box-shadow: none; }

.btn-primary {
  background-color: #eb0a1e;
  border-color: #eb0a1e;
  color: #FFFFFF;
  min-width: 120px;
  cursor: default; }
  .btn-primary:hover {
    background-color: #d10f20;
    border-color: #d10f20; }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #8f8f8f;
    background-color: #dcdcdc;
    border-color: #dcdcdc; }
  .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .btn-primary .show > .btn-primary.dropdown-toggle {
    color: #FFFFFF;
    background-color: #d10f20;
    border-color: #d10f20; }
  .btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus,
  .btn-primary .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: none; }

.btn-white {
  background-color: #fff;
  border-color: #dbdbdb;
  color: #1a1a1a;
  min-width: 100px; }
  .btn-white:hover {
    background-color: #444;
    color: #fff; }
    .btn-white:hover .filter-g {
      display: none; }
    .btn-white:hover .filter-hover {
      display: inline-block; }
  .btn-white:not(:disabled):not(.disabled).active:focus, .btn-white:not(:disabled):not(.disabled):active:focus,
  .btn-white .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: none; }

.btn-secondary {
  background-color: #FFFFFF;
  border-color: #979797;
  color: #444444; }
  .btn-secondary:hover {
    background-color: #d4d4d4;
    border-color: #d4d4d4; }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #8f8f8f;
    background-color: #dcdcdc;
    border-color: #dcdcdc; }
  .btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary .show > .btn-secondary.dropdown-toggle {
    color: #FFFFFF;
    background-color: #d4d4d4;
    border-color: #d4d4d4; }
  .btn-secondary:not(:disabled):not(.disabled).active:focus, .btn-secondary:not(:disabled):not(.disabled):active:focus,
  .btn-secondary .show > .btn-secondary.dropdown-toggle:focus {
    box-shadow: none; }

.btn-gray {
  background-color: #444444;
  border-color: #444444;
  color: #FFFFFF; }
  .btn-gray:hover {
    background-color: #313131;
    border-color: #313131;
    color: #FFFFFF; }
  .btn-gray.disabled, .btn-gray:disabled {
    color: #8f8f8f;
    background-color: #dcdcdc;
    border-color: #dcdcdc; }
  .btn-gray:not(:disabled):not(.disabled).active, .btn-gray:not(:disabled):not(.disabled):active, .btn-gray .show > .btn-secondary.dropdown-toggle {
    color: #FFFFFF;
    background-color: #313131;
    border-color: #313131; }
  .btn-gray:not(:disabled):not(.disabled).active:focus, .btn-gray:not(:disabled):not(.disabled):active:focus,
  .btn-gray .show > .btn-gray.dropdown-toggle:focus {
    box-shadow: none; }

.btn-blank {
  background-color: transparent;
  border-color: #979797;
  color: #444444; }
  .btn-blank:hover {
    background-color: transparent;
    border-color: #58595B; }
  .btn-blank.disabled, .btn-blank:disabled {
    color: #8f8f8f;
    background-color: #dcdcdc;
    border-color: #dcdcdc; }
  .btn-blank:not(:disabled):not(.disabled).active, .btn-blank:not(:disabled):not(.disabled):active, .btn-blank .show > .btn-secondary.dropdown-toggle {
    color: #FFFFFF;
    background-color: transparent;
    border-color: #979797; }
  .btn-blank:not(:disabled):not(.disabled).active:focus, .btn-blank:not(:disabled):not(.disabled):active:focus,
  .btn-blank .show > .btn-gray.dropdown-toggle:focus {
    box-shadow: none; }

.btn-tertiary {
  font-family: 'ToyotaDisplay', sans-serif;
  font-size: 14px;
  font-weight: normal;
  color: #eb0a1e;
  padding: 0;
  background: none;
  border: none; }
  .btn-tertiary:hover {
    color: #d10f20;
    background: none;
    border: none; }
  .btn-tertiary.disabled, .btn-tertiary:disabled {
    color: #8f8f8f;
    background: none;
    border: none; }
  .btn-tertiary:not(:disabled):not(.disabled).active, .btn-tertiary:not(:disabled):not(.disabled):active, .btn-tertiary .show > .btn-primary.dropdown-toggle {
    color: #d10f20;
    background: none;
    border: none; }
  .btn-tertiary:not(:disabled):not(.disabled).active:focus, .btn-tertiary:not(:disabled):not(.disabled):active:focus,
  .btn-tertiary .show > .btn-tertiary.dropdown-toggle:focus {
    box-shadow: none; }

@media (max-width: 375px) {
  .btn {
    font-size: 11px;
    padding: 8px 10px; }
  .btn-primary, .btn-white {
    min-width: 0; } }

.alert {
  border-radius: 3px;
  padding: 16px 50px;
  margin: 16px 0 0;
  border: none;
  max-width: 600px; }
  .alert-success {
    background-color: #47b280;
    border-color: #47b280;
    color: white; }
  .alert-danger {
    background-color: #eb0a1e;
    border-color: #eb0a1e;
    color: white; }
  .alert-message-assessment {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 99999999; }
  .alert__icon {
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translateY(-50%); }
  .alert__close-icon {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%); }

.schedule-icon1 {
  fill: none; }

.notfound {
  line-height: 585px;
  padding-left: 120px; }

.schedule-icon2 {
  stroke: #FFFFFF;
  transition: all 0.3s ease; }

.schedule-icon3 {
  fill: #FFFFFF;
  font-family: ToyotaText;
  font-weight: normal; }

.assessment-icon1 {
  fill: none; }

.assessment-icon2 {
  stroke: #FFFFFF; }

.action-icon1 {
  fill: none; }

.action-icon2 {
  stroke: #FFFFFF; }

.upload-icon1 {
  fill: none; }

.upload-icon2 {
  stroke: #FFFFFF; }

.sidebar__menu-item {
  position: relative;
  border-bottom: solid 1px #878787;
  text-decoration: none; }
  .sidebar__menu-item:hover {
    text-decoration: none; }
  .sidebar__menu-item.active .schedule-icon2 {
    stroke: #eb0a1e; }
  .sidebar__menu-item.active .assessment-icon2 {
    fill: #eb0a1e; }
  .sidebar__menu-item.active .action-icon2 {
    fill: #eb0a1e; }
  .sidebar__menu-item.active .upload-icon2 {
    fill: #eb0a1e; }
  .sidebar__menu-item.active .sidebar__menu-link {
    background-color: #f4f6f7;
    color: #000000; }
  .sidebar__menu-item.active .faq-icon-inactive {
    display: none; }
  .sidebar__menu-item.active .faq-icon-active {
    display: block; }
  .sidebar__menu-item.active .actions-svg use, .sidebar__menu-item.active .upload-svg use, .sidebar__menu-item.active .approval-svg use, .sidebar__menu-item.active .investigation-svg use {
    fill: #eb0a1e; }
  .sidebar__menu-item .faq-icon-active {
    display: none;
    margin-left: 1px; }

.sidebar__menu-item-last {
  border: none; }

.sidebar__menu-icon {
  float: none;
  margin: 0; }

.sidebar__menu-link {
  display: block;
  padding: 21px 5px 21px 5px;
  font-size: 13px;
  line-height: 1;
  letter-spacing: 0;
  color: #FFFFFF;
  min-height: 70px;
  transition: all 0.3s ease;
  text-align: center; }
  .sidebar__menu-link::after {
    display: block;
    content: "";
    clear: both; }
  .sidebar__menu-link .actions-svg use, .sidebar__menu-link .upload-svg use, .sidebar__menu-link .approval-svg use, .sidebar__menu-link .investigation-svg use {
    transition: all 0.3s ease; }
  .sidebar__menu-link:hover {
    /*background-color: #f5f6f7;*/
    color: #FFFFFF;
    text-decoration: none; }
    .sidebar__menu-link:hover .schedule-icon2 {
      stroke: #eb0a1e;
      transition: all 0.3s ease; }
    .sidebar__menu-link:hover .assessment-icon2 {
      fill: #eb0a1e; }
    .sidebar__menu-link:hover .actions-svg use, .sidebar__menu-link:hover .upload-svg use, .sidebar__menu-link:hover .approval-svg use, .sidebar__menu-link:hover .investigation-svg use {
      fill: #eb0a1e;
      transition: all 0.3s ease; }
    .sidebar__menu-link:hover .faq-icon-active {
      display: block;
      margin-left: 1px; }
    .sidebar__menu-link:hover .faq-icon-inactive {
      display: none; }

.sidebar__menu-text {
  display: block;
  margin-top: 9px; }

.nav-open .sidebar__menu-icon {
  float: left; }

.nav-open .sidebar__menu-text {
  visibility: visible;
  opacity: 1;
  filter: alpha(opacity=100); }

@media (min-width: 1025px) {
  .nav-open:not(.filter-open) .main-wrapper:after {
    display: none; } }

@media (min-width: 1025px) {
  .nav-open .sidebar__menu-icon {
    float: none; } }

@media (max-width: 1024px) {
  .sidebar__menu-text {
    visibility: visible;
    opacity: 1;
    filter: alpha(opacity=100);
    display: inline-block;
    margin-top: 15px; }
  .sidebar__menu-link {
    padding: 10px 0 10px 22px;
    text-align: left;
    min-height: 60px; }
  .sidebar__menu-icon {
    float: left;
    margin-top: 5px;
    margin-right: 15px; } }

@media (max-width: 767px) {
  .sidebar__menu-link {
    padding: 12px 0 12px 18px;
    min-height: 1px; }
  .nav-open .sidebar__menu-link {
    padding: 12px 0 12px 18px;
    min-height: 1px; } }

.legend-wrapper {
  padding: 10px 0; }

.records-count {
  font-size: 12px;
  color: #595959; }

.legend {
  float: right;
  margin: 0; }
  .legend__dot {
    float: left;
    margin-right: 24px;
    font-family: 'HelveticaNeue', sans-serif;
    font-size: 12px;
    color: #4a545d; }
    .legend__dot:before {
      content: "";
      width: 14px;
      height: 14px;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      border-radius: 50%;
      display: inline-block;
      margin: 0px 5px 0 0;
      vertical-align: bottom; }
    .legend__dot:last-child {
      margin: 0; }
  .legend__red:before {
    background-color: #eb0a1e; }
  .legend__yellow:before {
    background-color: #ffcc00; }
  .legend__green:before {
    background-color: #16ad0b; }

.no-document-found {
  text-align: center;
  font-size: 16px;
  line-height: 1.25;
  font-family: Helvetica, sans-serif;
  margin-top: 60px; }
  .no-document-found img {
    display: block;
    margin-bottom: 20px; }

.grid {
  margin-top: 8px; }
  .grid__labels {
    margin-bottom: 10px;
    padding: 0 20px;
    font-family: 'ToyotaDisplay', sans-serif;
    font-size: 12px;
    font-weight: bold;
    line-height: 1.54;
    color: #222222; }
  .grid__label-sort {
    cursor: pointer; }
  .grid__row {
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    border-radius: 4px;
    border: solid 1px #e6e6e6;
    background-color: #FFFFFF;
    margin-bottom: 10px;
    padding: 15px 20px;
    transition: all 0.3s ease;
    font-size: 14px;
    color: #595959;
    position: relative; }
    .grid__row:hover {
      box-shadow: 0 15px 25px 0 rgba(128, 128, 128, 0.2);
      border: solid 1px #e9e9e9;
      color: #222222; }
    .grid__row-link, .grid__row-link:hover {
      text-decoration: none; }
  .grid__labels-mobile {
    font-size: 12px;
    line-height: 1.67;
    color: #9b9b9b;
    display: block; }
  .grid__sort-icons {
    display: inline-block;
    vertical-align: middle;
    margin-left: 5px;
    position: absolute; }
  .grid__sort-arrow {
    border: 5px solid transparent;
    width: 0px;
    height: 0px;
    display: block;
    cursor: pointer; }
  .grid__sort-up-arrow {
    border-bottom-color: #bbbbbb; }
    .grid__sort-up-arrow--selected {
      border-bottom-color: #888888; }
  .grid__sort-down-arrow {
    border-top-color: #bbbbbb;
    margin-top: 1px; }
    .grid__sort-down-arrow--selected {
      border-top-color: #888888; }
  .grid__number {
    padding: 5px 15px;
    background: #e6e6e6;
    border-radius: 15px;
    font-size: 16px;
    color: #4a545d;
    font-family: helvetica, sans-serif; }

.grid-pagination {
  text-align: center; }
  .grid-pagination__link {
    text-align: center;
    width: 30px;
    height: 30px;
    display: block;
    border-radius: 15px;
    padding-top: 5px;
    transition: all 0.3s ease;
    color: #5a6872; }
  .grid-pagination__link:hover {
    text-decoration: none; }
  .grid-pagination__list-item:not(:disabled):not(.disabled) .grid-pagination__link:hover {
    background-color: #404040;
    color: #fff; }
  .grid-pagination__list-item.active .grid-pagination__link {
    background-color: #404040;
    text-decoration: none;
    color: #fff; }
  .grid-pagination__list-item.disabled a.grid-pagination__link {
    cursor: default;
    opacity: 0.6; }
    .grid-pagination__list-item.disabled a.grid-pagination__link:hover {
      color: #5a6872; }

@media (min-width: 992px) {
  .grid__labels-mobile {
    display: none; } }

@media (max-width: 767px) {
  .grid__row:hover {
    box-shadow: none; }
  .grid__labels {
    display: none; }
  .grid__row {
    padding: 10px;
    font-size: 14px;
    color: #222222; }
  .grid__column {
    margin-bottom: 5px; } }

@media (max-width: 367px) {
  .grid__row {
    font-size: 13px; } }

.form-group {
  margin-bottom: 20px; }

label {
  font-size: 12px;
  color: #222222;
  margin-bottom: 5px; }

.required {
  color: #eb0a1e; }

.custom-select {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  border: solid 1px #dcdcdc;
  background-color: #FFFFFF;
  font-size: 14px;
  color: #1a1a1a;
  width: 100%;
  display: block;
  height: 40px;
  background: #FFFFFF url("../images/down-selected.png") no-repeat right 0.75rem center; }
  .custom-select.not-selected {
    color: rgba(128, 128, 128, 0.5); }
    .custom-select.not-selected option {
      color: #1a1a1a; }

.custom-arrow > span + div {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.custom-arrow > div > div span {
  display: none; }

.custom-arrow > div > div svg {
  background: url("../images/down-selected.png") no-repeat;
  background-position: center; }
  .custom-arrow > div > div svg path {
    display: none; }

.form-control {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  border: solid 1px #dcdcdc;
  background-color: #FFFFFF;
  font-size: 14px;
  color: #1a1a1a;
  width: 100%;
  display: block;
  height: 40px; }

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1; }
  .inputfile + label {
    display: block;
    background: #fafafa;
    border-radius: 3px;
    border: solid 1px #dcdcdc;
    font-size: 14px;
    color: #6a6a6a;
    height: 40px;
    cursor: pointer;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; }
  .inputfile + label:before {
    content: "Browse";
    font-size: 14px;
    line-height: 40px;
    color: #FFFFFF;
    background: #eb0a1e;
    display: inline-block;
    text-align: center;
    width: 100px;
    border-radius: 3px 0 0 3px;
    margin: -1px 0 0 -1px; }

.rw-datetime-picker .rw-widget-container {
  border-radius: 3px;
  border: solid 1px #dcdcdc; }
  .rw-datetime-picker .rw-widget-container .rw-widget-input, .rw-datetime-picker .rw-widget-container .rw-filter-input {
    box-shadow: none;
    font-size: 14px;
    color: #1a1a1a;
    min-height: 38px; }
    .rw-datetime-picker .rw-widget-container .rw-widget-input::placeholder, .rw-datetime-picker .rw-widget-container .rw-filter-input::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      opacity: 0.5;
      /* Firefox */ }
    .rw-datetime-picker .rw-widget-container .rw-widget-input:-ms-input-placeholder, .rw-datetime-picker .rw-widget-container .rw-filter-input:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      opacity: 0.5; }
    .rw-datetime-picker .rw-widget-container .rw-widget-input::-ms-input-placeholder, .rw-datetime-picker .rw-widget-container .rw-filter-input::-ms-input-placeholder {
      /* Microsoft Edge */
      opacity: 0.5; }
  .rw-datetime-picker .rw-widget-container .rw-select-bordered {
    border: none;
    background-image: url("../images/calendar.svg");
    background-repeat: no-repeat;
    background-position: calc(100% - 5px) center;
    opacity: 0.6;
    width: 100%;
    position: absolute;
    right: 0;
    top: 0;
    background-position: calc(100% - 5px) center;
    cursor: pointer; }
    .rw-datetime-picker .rw-widget-container .rw-select-bordered .rw-btn-select {
      width: 100%; }
  .rw-datetime-picker .rw-widget-container .rw-select-bordered span:before {
    content: none; }

.rw-datetime-picker .rw-select-bordered:hover,
.rw-datetime-picker .rw-select-bordered:active {
  background-color: transparent; }

.rw-datetime-picker.rw-state-disabled .rw-select-bordered,
.rw-datetime-picker.rw-state-disabled .rw-select-bordered:hover {
  background-image: url("../images/calendar.svg"); }

.rw-datetime-picker.rw-state-disabled .rw-btn-select[disabled] {
  pointer-events: all; }

.rw-datetime-picker.rw-state-disabled .rw-select-bordered:hover {
  background-color: inherit; }

textarea.form-control {
  min-height: 90px;
  color: #8b8b8b; }

.custom-multiselect button {
  width: 100%;
  border-radius: 3px;
  border: 1px solid #dcdcdc;
  box-shadow: none;
  background: #FFFFFF url("../images/down-selected.png") no-repeat right 0.75rem center;
  height: 40px; }
  .custom-multiselect button svg {
    display: none; }
  .custom-multiselect button + div {
    width: 100%; }

.custom-multiselect .css-1v99tuv {
  padding-right: 20px;
  color: #1a1a1a; }

.custom-multiselect-dropdown {
  width: 100%;
  border: 1px solid #dcdcdc;
  margin-top: -9px; }
  .custom-multiselect-dropdown .css-1kuo3lu,
  .custom-multiselect-dropdown .css-15brt4p {
    min-width: 0;
    width: auto; }
  .custom-multiselect-dropdown .css-lc91z5 {
    width: auto;
    min-width: 0;
    color: #99a6b5;
    position: relative;
    cursor: pointer; }
    .custom-multiselect-dropdown .css-lc91z5 input {
      visibility: hidden; }
    .custom-multiselect-dropdown .css-lc91z5:active {
      background-color: transparent; }
    @media (min-width: 1025px) {
      .custom-multiselect-dropdown .css-lc91z5:hover {
        background-color: transparent; } }
    @media (max-width: 1024px) {
      .custom-multiselect-dropdown .css-lc91z5:hover {
        background-color: inherit; } }
    .custom-multiselect-dropdown .css-lc91z5:before {
      content: "";
      width: 15px;
      height: 15px;
      border: 1px solid #d0d9e5;
      display: block;
      border-radius: 2px;
      position: absolute;
      top: 9px;
      left: 12px; }
  .custom-multiselect-dropdown .css-19lyk4y {
    width: auto;
    min-width: 0;
    color: #39414d;
    font-weight: normal;
    position: relative;
    background-color: transparent;
    cursor: pointer; }
    .custom-multiselect-dropdown .css-19lyk4y:active {
      background-color: transparent; }
    .custom-multiselect-dropdown .css-19lyk4y input {
      visibility: hidden; }
    .custom-multiselect-dropdown .css-19lyk4y:before {
      content: "";
      width: 15px;
      height: 15px;
      background-color: #454648;
      top: 9px;
      left: 12px;
      position: absolute;
      border-radius: 2px; }
    .custom-multiselect-dropdown .css-19lyk4y:after {
      content: "";
      height: 6px;
      width: 11px;
      display: block;
      border-left: 2px solid white;
      border-bottom: 2px solid white;
      transform: rotate(-45deg);
      position: absolute;
      top: 12px;
      left: 14px; }
  .custom-multiselect-dropdown .css-vfox7y,
  .custom-multiselect-dropdown .css-1vcobox,
  .custom-multiselect-dropdown .css-11unzgr {
    max-height: 150px; }

.dropdown-with-search > div > div > span {
  display: none; }

.dropdown-with-search > div > div svg {
  display: none; }

.dropdown-with-search .css-1492t68 {
  opacity: 0.5; }

.dropdown-with-search .css-1g6gooi, .dropdown-with-search .css-xp4uvy {
  color: #1a1a1a; }

.dropdown-with-search .css-1ep9fjw,
.dropdown-with-search .css-1uq0kb5 {
  background: transparent url("../images/down-selected.png") no-repeat right 0.75rem center;
  width: 28px; }

.custom-checkbox {
  display: inline-block;
  position: relative;
  padding-left: 24px;
  vertical-align: top;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }
  .custom-checkbox:hover .custom-checkmark {
    border: 1px solid #eb0a1e; }
  .custom-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    left: 0; }
    .custom-checkbox input:checked ~ .custom-checkmark {
      background-color: #eb0a1e;
      border: 1px solid transparent; }
      .custom-checkbox input:checked ~ .custom-checkmark:after {
        display: block; }
  .custom-checkbox .custom-checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: #fbfbfb;
    border: 1px solid #dddddd;
    border-radius: 3px; }
    .custom-checkbox .custom-checkmark:after {
      content: "";
      position: absolute;
      display: none;
      top: 2px;
      left: 6px;
      width: 5px;
      height: 9px;
      background: #ffffff00;
      border-right: 2px solid #FFF;
      border-bottom: 2px solid #fff;
      transform: rotate(40deg); }

.show-filters {
  text-align: right; }
  .show-filters .btn-toggle {
    background: none;
    border: solid 1px #d8d8d8;
    padding: 7px 10px;
    font-size: 12px;
    color: #4a545d;
    font-family: Helvetica, sans-serif;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    border-radius: 4px;
    margin-top: 3px;
    vertical-align: top; }
    .show-filters .btn-toggle.active .show-filter-text {
      display: none; }
    .show-filters .btn-toggle.active .hide-filter-text {
      display: block; }
    .show-filters .btn-toggle.focus, .show-filters .btn-toggle:focus {
      box-shadow: none; }
    .show-filters .btn-toggle img {
      float: left; }
    .show-filters .btn-toggle .show-filter-text {
      display: block;
      float: left;
      margin: 2px 0 0 5px; }
    .show-filters .btn-toggle .hide-filter-text {
      display: none;
      float: left;
      margin: 2px 0 0 5px; }
  .show-filters .filter-hover {
    display: none; }

.filter-panel {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;
  height: 100%;
  transition: all 0.3s ease;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  transform: translateX(100%);
  width: 246px; }
  .filter-panel__close, .modal-header .close > span, .modal-header .cst-close > span {
    position: absolute;
    right: 16px;
    top: 16px;
    z-index: 1;
    width: 15px;
    height: 15px;
    display: block;
    cursor: pointer; }
  .filter-panel__close:before, .modal-header .close > span:before, .modal-header .cst-close > span:before, .filter-panel__close:after, .modal-header .close > span:after, .modal-header .cst-close > span:after {
    content: "";
    width: 20px;
    height: 1px;
    background-color: #58595B;
    display: block;
    position: absolute; }
  .filter-panel__close:before, .modal-header .close > span:before, .modal-header .cst-close > span:before {
    transform: rotate(45deg);
    left: -2px;
    top: 7px; }
  .filter-panel__close:after, .modal-header .close > span:after, .modal-header .cst-close > span:after {
    transform: rotate(-45deg);
    left: -2px;
    top: 7px; }
  .filter-panel__header {
    position: relative;
    padding: 16px;
    border-bottom: solid 1px #d8d8d8; }
    .filter-panel__header h6 {
      margin-bottom: 15px; }
  .filter-panel__form-wrapper {
    padding: 0 14px 14px; }
  .filter-panel__reset-btn {
    border-radius: 10px;
    border: solid 1px #e6e6e6;
    background-color: #ffffff;
    padding: 2px 10px;
    min-height: 20px;
    display: inline-block;
    font-size: 11px;
    color: #222222;
    cursor: pointer; }
    @media (min-width: 1025px) {
      .filter-panel__reset-btn:hover, .filter-panel__reset-btn:focus {
        background-color: #444444;
        color: #fff !important;
        text-decoration: none; } }
  .filter-panel__active-btn {
    border-radius: 10px;
    border: solid 1px #eb0a1e;
    background-color: #eb0a1e;
    padding: 2px 10px;
    min-height: 20px;
    display: inline-block;
    font-size: 11px;
    color: #fff !important;
    cursor: pointer; }
    .filter-panel__active-btn:hover, .filter-panel__active-btn:focus {
      background-color: #d10f20;
      color: #fff !important; }
  .filter-panel__disabled-btn {
    color: #8f8f8f;
    background-color: #dcdcdc;
    border-color: #dcdcdc;
    padding: 2px 10px;
    min-height: 20px;
    display: inline-block;
    font-size: 11px;
    border-radius: 10px;
    pointer-events: none; }
  .filter-panel .filter-wrapper {
    position: absolute;
    right: 0;
    top: 70px;
    z-index: 9999;
    box-shadow: -4px 0 5px -1px #e1e2e2;
    background-color: #fbfbfb;
    width: 208px;
    min-height: 100%;
    /* #FSelect_StatusLookup{
      button, .custom-multiselect-dropdown {
        //option{
          text-transform: uppercase;
      //  }
      
      }
    } */ }
    @media (max-width: 1024px) {
      .filter-panel .filter-wrapper {
        top: 65px; } }

.filter-open .filter-panel {
  transform: translateX(0); }

.filter-open .show-filters {
  opacity: 0; }

.rw-calendar-popup.rw-popup-container {
  width: auto;
  min-width: 222px;
  left: auto; }

.custom-radio {
  display: inline-block;
  position: relative;
  padding-left: 24px;
  margin-right: 25px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }
  .custom-radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .custom-radio input:checked ~ .custom-checkmark {
      background-color: #eb0a1e;
      border: 1px solid transparent; }
      .custom-radio input:checked ~ .custom-checkmark:after {
        display: block; }
  .custom-radio .custom-checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: #fbfbfb;
    border-radius: 50%;
    border: 1px solid #222222; }
    .custom-radio .custom-checkmark:after {
      content: "";
      position: absolute;
      display: none;
      top: 2px;
      left: 5px;
      width: 4px;
      height: 8px;
      background: #ffffff00;
      border-right: 2px solid #FFF;
      border-bottom: 2px solid #fff;
      transform: rotate(40deg); }

.location-status {
  background-color: rgba(216, 216, 216, 0.3);
  margin: 0 -14px 24px;
  padding: 24px 15px 4px; }

.downloadButton {
  display: inline; }

.downloadImage {
  height: 32px;
  width: 32px; }

.nav-tabs {
  border-bottom-color: #9b9b9b; }
  .nav-tabs .nav-item {
    margin-right: 20px; }
    .nav-tabs .nav-item .nav-link {
      padding: 14px 12px;
      font-size: 16px;
      font-family: 'ToyotaDisplay', sans-serif;
      color: #222222;
      letter-spacing: 0.8px;
      font-weight: normal; }
      .nav-tabs .nav-item .nav-link:hover {
        color: #222222;
        border-color: #d3d3d3; }
    .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-item .nav-link.active {
      box-shadow: 0 -4px 0px 0px #eb0a1f inset;
      border-bottom: none;
      border-color: #d3d3d3; }

.main-content header[class*="MuiAppBar-root"] {
  box-shadow: none;
  border-bottom: 1px solid #dddddd;
  margin-top: 10px; }
  .main-content header[class*="MuiAppBar-root"] .MuiTabs-root {
    width: 100%; }

div[class*="MuiTabs-flexContainer"] .MuiPrivateTabScrollButton-root {
  width: auto; }

div[class*="MuiTabs-flexContainer"] .MuiTab-label {
  text-transform: capitalize;
  line-height: 1.71; }

div[class*="MuiTabs-flexContainer"] button[class*="MuiTabs-scrollButtons"] {
  background-color: #eb0a1e;
  border-radius: 3px;
  height: 28px;
  width: 28px;
  margin-top: 12px; }
  div[class*="MuiTabs-flexContainer"] button[class*="MuiTabs-scrollButtons"]:first-child {
    margin-right: 10px; }
  div[class*="MuiTabs-flexContainer"] button[class*="MuiTabs-scrollButtons"]:last-child {
    margin-left: 10px; }
  div[class*="MuiTabs-flexContainer"] button[class*="MuiTabs-scrollButtons"] svg {
    color: #FFFFFF; }

div[class*="MuiTabs-flexContainer"] button[class*="MuiTab-textColorPrimary"] {
  padding: 10px 30px;
  font-size: 14px;
  font-family: 'ToyotaText', sans-serif;
  color: #1a1a1a;
  letter-spacing: normal;
  font-weight: normal;
  border: 1px solid transparent;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  outline: none;
  min-height: 1px; }
  div[class*="MuiTabs-flexContainer"] button[class*="MuiTab-textColorPrimary"]:hover {
    color: #1a1a1a;
    border-color: #d3d3d3;
    background-color: #FFFFFF; }
  div[class*="MuiTabs-flexContainer"] button[class*="MuiTab-textColorPrimary"] span[class*="MuiTab-wrapper"] {
    flex-direction: row; }
    div[class*="MuiTabs-flexContainer"] button[class*="MuiTab-textColorPrimary"] span[class*="MuiTab-wrapper"] img {
      order: 2;
      margin-left: 10px;
      height: 16px; }
  div[class*="MuiTabs-flexContainer"] button[class*="MuiTab-textColorPrimary"][class*="MuiTab-selected"] {
    color: #1a1a1a;
    border-bottom: none;
    border-color: #d3d3d3;
    background-color: #FFFFFF; }

div[class*="MuiTabs-flexContainer"] span[class*="MuiTab-labelContainer"] {
  padding: 0; }

div[class*="MuiTabs-flexContainer"] span[class*="MuiPrivateTabIndicator-colorPrimary"] {
  height: 3px;
  background-color: #eb0a1f; }

@media (max-width: 767px) {
  .nav-tabs .nav-item .nav-link {
    font-size: 15px;
    padding: 12px 8px; }
  div[class*="MuiTabs-flexContainer"] .MuiTab-label {
    font-size: 15px; } }

.popup-overlay {
  background: rgba(0, 0, 0, 0.5) !important;
  z-index: 99999 !important;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  pointer-events: auto; }

.popup-content {
  width: 100% !important;
  padding: 0 !important;
  background: none !important;
  border: none !important; }

.popup.main-wrapper {
  background: none; }

.popup .main-content {
  min-height: 100%;
  padding-top: 22px;
  margin: 0; }

@media (min-width: 1025px) {
  .popup__wrapper-action {
    min-width: 538px; } }

.popup__wrapper {
  max-width: 538px;
  width: 100%;
  margin: 0 auto;
  background: white;
  border-radius: 6px;
  box-shadow: 0 2px 11px 0 #e1e2e2; }

.popup__header {
  padding: 16px 24px;
  border-bottom: 2px solid #d8d8d8;
  text-align: center; }
  .popup__header h5 {
    margin: 0;
    width: 100%; }

.popup__body {
  padding: 15px 24px; }

.popup__close {
  position: absolute;
  right: 24px;
  top: 40px;
  z-index: 1; }
  @media (max-width: 560px) {
    .popup__close {
      right: 34px; } }
  .popup__close label {
    cursor: pointer; }

.popup__button-wrapper {
  padding-top: 16px;
  text-align: center; }

.modal-open .cst-close {
  position: absolute;
  width: 23px;
  height: 23px;
  right: 8px;
  background: #66339900;
  top: 26px;
  z-index: 1;
  opacity: 0.5;
  cursor: pointer; }
  .modal-open .cst-close:hover {
    opacity: 1; }
  .modal-open .cst-close:before, .modal-open .cst-close:after {
    content: "";
    width: 20px;
    height: 1px;
    background-color: #58595B;
    display: block;
    position: absolute; }
  .modal-open .cst-close:before {
    transform: rotate(45deg);
    left: -2px;
    top: 7px; }
  .modal-open .cst-close:after {
    transform: rotate(-45deg);
    left: -2px;
    top: 7px; }

.modal-open .modal-header .close {
  display: none; }

.modal-header .close:focus, .modal-header .cst-close:focus {
  outline: 0; }

.modal-header .close > span, .modal-header .cst-close > span {
  font-size: 0;
  margin-top: 10px; }

@media (max-width: 767px) {
  .popup.main-wrapper {
    margin-top: 0; }
  .popup__header {
    padding: 16px 20px; }
  .popup__body {
    padding: 15px 20px; }
  .popup__footer {
    padding: 16px 20px; }
  .popup__close img {
    width: 16px; } }

.heading-container {
  display: inline-flex; }

.reviewStatusText {
  border-style: double;
  border-radius: 5px;
  font-weight: bold;
  display: table-cell;
  vertical-align: middle;
  border: 2.5px solid;
  padding: 6px 10px;
  height: 2.5em;
  margin-left: 15px;
  font-size: 14px; }

.In-progressStatus {
  color: #4d92e2;
  border-color: #4d92e2; }

.CompletedStatus {
  color: green;
  border-color: #62ce7b; }

.DeletedStatus {
  color: #df6868;
  border-color: #df6868; }

.OtherStatus {
  color: #f09220;
  border-color: #e59737; }

.close__wrapper {
  max-width: 538px;
  width: 100%;
  position: relative;
  margin: 0 auto; }

.more-menu .close__wrapper, .small-popup-wrap .close__wrapper {
  max-width: 440px; }

.small-popup {
  max-width: 440px; }

.deletepopup-container {
  padding: 25px 16px 70px; }

.com-small-container {
  padding: 25px 16px; }

.deletepopup_wrapper .popup__close, .com-popup_wrapper .popup__close {
  top: 20px; }

.deletepopup_wrapper .popup__close-comfirmation, .com-popup_wrapper .popup__close-comfirmation {
  top: 40px; }

.deletepopup_wrapper .upload-review-pop .popup__close, .com-popup_wrapper .upload-review-pop .popup__close {
  top: 12px; }

@font-face {
  font-family: Toyota;
  src: url("../../fonts/ToyotaText_Rg_0.ttf"); }

@media (min-width: 1200px) {
  .container {
    max-width: 100%; } }

.container {
  width: 100%;
  height: 100%;
  font-family: Toyota;
  font-size: 14px;
  padding: 0;
  margin: 0; }
  .container.overflow-x-hidden {
    overflow-x: hidden;
    max-width: 100%; }
  .container .popup-title {
    display: block;
    font-size: 14px;
    padding-bottom: 10px; }
  .container .left-popover span {
    padding-bottom: 14px; }
  .container .userText {
    padding: 15px;
    display: block; }
  .container .skipTag {
    color: black;
    float: none; }
  .container .start-image {
    display: block;
    width: 100%;
    height: 100%;
    background-image: url("../images/slide-1.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-size: 100% auto;
    padding-bottom: calc(716 / 1440 * 100%);
    text-align: center;
    color: black; }
    @media (max-width: 1024px) {
      .container .start-image {
        background-image: url("../images/tab-guide/tab-1.png");
        padding-bottom: calc(1024 / 768 * 100%); } }
    @media (max-width: 767px) {
      .container .start-image {
        background-image: url("../images/mobile-guide/mobile-1.png");
        padding-bottom: calc(480 / 320 * 100%); } }
    .container .start-image.last {
      display: none;
      background-image: url("../images/slide-25.png");
      padding-bottom: calc(716 / 1440 * 100%); }
      @media (max-width: 1024px) {
        .container .start-image.last {
          background-image: url("../images/tab-guide/tab-25.png");
          padding-bottom: calc(1024 / 768 * 100%); } }
      @media (max-width: 767px) {
        .container .start-image.last {
          background-image: url("../images/mobile-guide/mobile-25.png");
          padding-bottom: calc(480 / 320 * 100%); } }
    .container .start-image .lastPage {
      width: 350px;
      margin-top: 20%;
      color: white;
      left: 0;
      right: 0;
      margin: 0 auto;
      top: 50%;
      transform: translateY(-50%); }
    .container .start-image .help-popup {
      width: 420px;
      background: white;
      height: 200px;
      border-radius: 6px;
      margin: 0 auto;
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      transform: translateY(-50%); }
      .container .start-image .help-popup .start-page-title {
        font-family: Toyota;
        font-size: 20px;
        border-bottom: 1px solid #ddd;
        display: block;
        width: 100%;
        padding: 12px 0 12px 0; }
  .container .btn {
    padding: 10px 45px;
    background-color: #EB0A1E;
    font-family: Toyota;
    color: white;
    font-size: 16px;
    border: none;
    border-radius: 38px;
    margin-top: 8px;
    cursor: pointer;
    float: right;
    margin-right: 16px; }
    .container .btn:hover {
      background-color: #FF0117; }
  .container .comm-image {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-size: 100% auto;
    color: white;
    display: none;
    padding-bottom: calc(900 / 1440 * 100%); }
    .container .comm-image.image-1 {
      background-image: url("../images/slide-2.png");
      padding-bottom: calc(716 / 1440 * 100%); }
      @media (max-width: 1024px) {
        .container .comm-image.image-1 {
          background-image: url("../images/tab-guide/tab-2.png");
          padding-bottom: calc(1024 / 768 * 100%); } }
      @media (max-width: 767px) {
        .container .comm-image.image-1 {
          background-image: url("../images/mobile-guide/mobile-2.png");
          padding-bottom: calc(480 / 320 * 100%); } }
    .container .comm-image.image-2 {
      background-image: url("../images/slide-3.png");
      padding-bottom: calc(716 / 1440 * 100%); }
      @media (max-width: 1024px) {
        .container .comm-image.image-2 {
          background-image: url("../images/tab-guide/tab-3.png");
          padding-bottom: calc(1024 / 768 * 100%); } }
      @media (max-width: 767px) {
        .container .comm-image.image-2 {
          background-image: url("../images/mobile-guide/mobile-3.png");
          padding-bottom: calc(480 / 320 * 100%); } }
    .container .comm-image.image-3 {
      background-image: url("../images/slide-4.png");
      padding-bottom: calc(716 / 1440 * 100%); }
      @media (max-width: 1024px) {
        .container .comm-image.image-3 {
          background-image: url("../images/tab-guide/tab-4.png");
          padding-bottom: calc(1024 / 768 * 100%); } }
      @media (max-width: 767px) {
        .container .comm-image.image-3 {
          background-image: url("../images/mobile-guide/mobile-4.png");
          padding-bottom: calc(480 / 320 * 100%); } }
    .container .comm-image.image-4 {
      background-image: url("../images/slide-5.png");
      padding-bottom: calc(716 / 1440 * 100%); }
      @media (max-width: 1024px) {
        .container .comm-image.image-4 {
          background-image: url("../images/tab-guide/tab-5.png");
          padding-bottom: calc(1024 / 768 * 100%); } }
      @media (max-width: 767px) {
        .container .comm-image.image-4 {
          background-image: url("../images/mobile-guide/mobile-5.png");
          padding-bottom: calc(480 / 320 * 100%); } }
    .container .comm-image.image-5 {
      background-image: url("../images/slide-6.png");
      padding-bottom: calc(716 / 1440 * 100%); }
      @media (max-width: 1024px) {
        .container .comm-image.image-5 {
          background-image: url("../images/tab-guide/tab-6.png");
          padding-bottom: calc(1024 / 768 * 100%); } }
      @media (max-width: 767px) {
        .container .comm-image.image-5 {
          background-image: url("../images/mobile-guide/mobile-6.png");
          padding-bottom: calc(480 / 320 * 100%); } }
    .container .comm-image.image-6 {
      background-image: url("../images/slide-7.png");
      padding-bottom: calc(716 / 1440 * 100%); }
      @media (max-width: 1024px) {
        .container .comm-image.image-6 {
          background-image: url("../images/tab-guide/tab-7.png");
          padding-bottom: calc(1024 / 768 * 100%); } }
      @media (max-width: 767px) {
        .container .comm-image.image-6 {
          background-image: url("../images/mobile-guide/mobile-7.png");
          padding-bottom: calc(480 / 320 * 100%); } }
    .container .comm-image.image-7 {
      background-image: url("../images/slide-8.png");
      padding-bottom: calc(716 / 1440 * 100%); }
      @media (max-width: 1024px) {
        .container .comm-image.image-7 {
          background-image: url("../images/tab-guide/tab-8.png");
          padding-bottom: calc(1024 / 768 * 100%); } }
      @media (max-width: 767px) {
        .container .comm-image.image-7 {
          background-image: url("../images/mobile-guide/mobile-8.png");
          padding-bottom: calc(480 / 320 * 100%); } }
    .container .comm-image.image-8 {
      background-image: url("../images/slide-9.png");
      padding-bottom: calc(716 / 1440 * 100%); }
      @media (max-width: 1024px) {
        .container .comm-image.image-8 {
          background-image: url("../images/tab-guide/tab-9.png");
          padding-bottom: calc(1024 / 768 * 100%); } }
      @media (max-width: 767px) {
        .container .comm-image.image-8 {
          background-image: url("../images/mobile-guide/mobile-9.png");
          padding-bottom: calc(480 / 320 * 100%); } }
    .container .comm-image.image-9 {
      background-image: url("../images/slide-10.png");
      padding-bottom: calc(716 / 1440 * 100%); }
      @media (max-width: 1024px) {
        .container .comm-image.image-9 {
          background-image: url("../images/tab-guide/tab-10.png");
          padding-bottom: calc(1024 / 768 * 100%); } }
      @media (max-width: 767px) {
        .container .comm-image.image-9 {
          background-image: url("../images/mobile-guide/mobile-10.png");
          padding-bottom: calc(480 / 320 * 100%); } }
    .container .comm-image.image-10 {
      background-image: url("../images/slide-11.png");
      padding-bottom: calc(716 / 1440 * 100%); }
      @media (max-width: 1024px) {
        .container .comm-image.image-10 {
          background-image: url("../images/tab-guide/tab-11.png");
          padding-bottom: calc(1024 / 768 * 100%); } }
      @media (max-width: 767px) {
        .container .comm-image.image-10 {
          background-image: url("../images/mobile-guide/mobile-11.png");
          padding-bottom: calc(480 / 320 * 100%); } }
    .container .comm-image.image-11 {
      background-image: url("../images/slide-12.png");
      padding-bottom: calc(716 / 1440 * 100%); }
      @media (max-width: 1024px) {
        .container .comm-image.image-11 {
          background-image: url("../images/tab-guide/tab-12.png");
          padding-bottom: calc(1024 / 768 * 100%); } }
      @media (max-width: 767px) {
        .container .comm-image.image-11 {
          background-image: url("../images/mobile-guide/mobile-12.png");
          padding-bottom: calc(480 / 320 * 100%); } }
    .container .comm-image.image-12 {
      background-image: url("../images/slide-13.png");
      padding-bottom: calc(716 / 1440 * 100%); }
      @media (max-width: 1024px) {
        .container .comm-image.image-12 {
          background-image: url("../images/tab-guide/tab-13.png");
          padding-bottom: calc(1024 / 768 * 100%); } }
      @media (max-width: 767px) {
        .container .comm-image.image-12 {
          background-image: url("../images/mobile-guide/mobile-13.png");
          padding-bottom: calc(480 / 320 * 100%); } }
    .container .comm-image.image-13 {
      background-image: url("../images/slide-14.png");
      padding-bottom: calc(716 / 1440 * 100%); }
      @media (max-width: 1024px) {
        .container .comm-image.image-13 {
          background-image: url("../images/tab-guide/tab-14.png");
          padding-bottom: calc(1024 / 768 * 100%); } }
      @media (max-width: 767px) {
        .container .comm-image.image-13 {
          background-image: url("../images/mobile-guide/mobile-14.png");
          padding-bottom: calc(480 / 320 * 100%); } }
    .container .comm-image.image-14 {
      background-image: url("../images/slide-15.png");
      padding-bottom: calc(716 / 1440 * 100%); }
      @media (max-width: 1024px) {
        .container .comm-image.image-14 {
          background-image: url("../images/tab-guide/tab-15.png");
          padding-bottom: calc(1024 / 768 * 100%); } }
      @media (max-width: 767px) {
        .container .comm-image.image-14 {
          background-image: url("../images/mobile-guide/mobile-15.png");
          padding-bottom: calc(480 / 320 * 100%); } }
    .container .comm-image.image-15 {
      background-image: url("../images/slide-16.png");
      padding-bottom: calc(716 / 1440 * 100%); }
      @media (max-width: 1024px) {
        .container .comm-image.image-15 {
          background-image: url("../images/tab-guide/tab-16.png");
          padding-bottom: calc(1024 / 768 * 100%); } }
      @media (max-width: 767px) {
        .container .comm-image.image-15 {
          background-image: url("../images/mobile-guide/mobile-16.png");
          padding-bottom: calc(480 / 320 * 100%); } }
    .container .comm-image.image-16 {
      background-image: url("../images/slide-17.png");
      padding-bottom: calc(716 / 1440 * 100%); }
      @media (max-width: 1024px) {
        .container .comm-image.image-16 {
          background-image: url("../images/tab-guide/tab-17.png");
          padding-bottom: calc(1024 / 768 * 100%); } }
      @media (max-width: 767px) {
        .container .comm-image.image-16 {
          background-image: url("../images/mobile-guide/mobile-17.png");
          padding-bottom: calc(480 / 320 * 100%); } }
    .container .comm-image.image-17 {
      background-image: url("../images/slide-18.png");
      padding-bottom: calc(716 / 1440 * 100%); }
      @media (max-width: 1024px) {
        .container .comm-image.image-17 {
          background-image: url("../images/tab-guide/tab-18.png");
          padding-bottom: calc(1024 / 768 * 100%); } }
      @media (max-width: 767px) {
        .container .comm-image.image-17 {
          background-image: url("../images/mobile-guide/mobile-18.png");
          padding-bottom: calc(480 / 320 * 100%); } }
    .container .comm-image.image-18 {
      background-image: url("../images/slide-19.png");
      padding-bottom: calc(716 / 1440 * 100%); }
      @media (max-width: 1024px) {
        .container .comm-image.image-18 {
          background-image: url("../images/tab-guide/tab-19.png");
          padding-bottom: calc(1024 / 768 * 100%); } }
      @media (max-width: 767px) {
        .container .comm-image.image-18 {
          background-image: url("../images/mobile-guide/mobile-19.png");
          padding-bottom: calc(480 / 320 * 100%); } }
    .container .comm-image.image-19 {
      background-image: url("../images/slide-20.png");
      padding-bottom: calc(716 / 1440 * 100%); }
      @media (max-width: 1024px) {
        .container .comm-image.image-19 {
          background-image: url("../images/tab-guide/tab-20.png");
          padding-bottom: calc(1024 / 768 * 100%); } }
      @media (max-width: 767px) {
        .container .comm-image.image-19 {
          background-image: url("../images/mobile-guide/mobile-20.png");
          padding-bottom: calc(480 / 320 * 100%); } }
    .container .comm-image.image-20 {
      background-image: url("../images/slide-21.png");
      padding-bottom: calc(716 / 1440 * 100%); }
      @media (max-width: 1024px) {
        .container .comm-image.image-20 {
          background-image: url("../images/tab-guide/tab-21.png");
          padding-bottom: calc(1024 / 768 * 100%); } }
      @media (max-width: 767px) {
        .container .comm-image.image-20 {
          background-image: url("../images/mobile-guide/mobile-21.png");
          padding-bottom: calc(480 / 320 * 100%); } }
    .container .comm-image.image-21 {
      background-image: url("../images/slide-22.png");
      padding-bottom: calc(716 / 1440 * 100%); }
      @media (max-width: 1024px) {
        .container .comm-image.image-21 {
          background-image: url("../images/tab-guide/tab-22.png");
          padding-bottom: calc(1024 / 768 * 100%); } }
      @media (max-width: 767px) {
        .container .comm-image.image-21 {
          background-image: url("../images/mobile-guide/mobile-22.png");
          padding-bottom: calc(480 / 320 * 100%); } }
    .container .comm-image.image-22 {
      background-image: url("../images/slide-23.png");
      padding-bottom: calc(716 / 1440 * 100%); }
      @media (max-width: 1024px) {
        .container .comm-image.image-22 {
          background-image: url("../images/tab-guide/tab-23.png");
          padding-bottom: calc(1024 / 768 * 100%); } }
      @media (max-width: 767px) {
        .container .comm-image.image-22 {
          background-image: url("../images/mobile-guide/mobile-23.png");
          padding-bottom: calc(480 / 320 * 100%); } }
    .container .comm-image.image-23 {
      background-image: url("../images/slide-24.png");
      padding-bottom: calc(716 / 1440 * 100%); }
      @media (max-width: 1024px) {
        .container .comm-image.image-23 {
          background-image: url("../images/tab-guide/tab-24.png");
          padding-bottom: calc(1024 / 768 * 100%); } }
      @media (max-width: 767px) {
        .container .comm-image.image-23 {
          background-image: url("../images/mobile-guide/mobile-24.png");
          padding-bottom: calc(480 / 320 * 100%); } }
  .container .button-1 {
    float: none; }
  .container .left-popover {
    background-image: url("../images/left-popover.png");
    background-repeat: no-repeat;
    width: 345px;
    height: 106px;
    color: black;
    display: none; }
  .container .right-popover {
    background-image: url("../images/right-popover.png");
    background-repeat: no-repeat;
    width: 355px;
    height: 106px;
    color: black;
    display: none; }
  .container .right-popover-small {
    background-image: url("../images/right-popover-small.png");
    background-repeat: no-repeat;
    width: 355px;
    height: 87px;
    color: black;
    display: none; }
  .container .top-popover {
    background-image: url("../images/top-popover.png");
    background-repeat: no-repeat;
    width: 345px;
    height: 118px;
    color: black;
    display: none; }
  .container .top-popover-small {
    background-image: url("../images/top-popover-small.png");
    background-repeat: no-repeat;
    width: 345px;
    height: 87px;
    min-height: 100px;
    color: black;
    display: none; }
  .container .bottom-popover {
    background-image: url("../images/bottom-popover.png");
    background-repeat: no-repeat;
    width: 345px;
    height: 118px;
    color: black;
    display: none; }
  .container .bottom-popover-2 {
    background-image: url("../images/bottom-popover-2.png");
    background-repeat: no-repeat;
    width: 345px;
    height: 131px;
    color: black;
    display: none; }
  .container .left-popover p {
    padding: 13px 11px 20px 35px; }
  .container .right-popover-small p {
    padding: 20px 28px 20px 20px; }
  .container .right-popover p {
    padding: 14px 28px 20px 20px; }
  .container .right-popover span {
    padding-bottom: 10px; }
  .container .top-popover p {
    padding: 23px 35px 20px 24px; }
  .container .top-popover span {
    padding-bottom: 10px; }
  .container .top-popover-small p {
    padding: 26px 35px 20px 24px; }
  .container .bottom-popover p {
    padding: 17px 35px 20px 24px; }
  .container .bottom-popover-2 p {
    padding: 14px 35px 20px 24px; }
  .container .bottom-popover-2 span {
    padding-bottom: 10px; }
  @media (max-width: 767px) {
    .container .last .popover-bottom {
      width: 180px; } }
  .container .popover-bottom {
    position: fixed;
    left: 50%;
    bottom: 30px;
    transform: translate(-50%);
    margin-left: 15px; }
    @media (max-width: 767px) {
      .container .popover-bottom {
        width: 290px; } }
  .container .popover-1 {
    position: absolute;
    left: 70px;
    top: 100px; }
  .container .popover-2 {
    position: absolute;
    left: 70px;
    top: 162px; }
  .container .popover-3 {
    position: absolute;
    left: 70px;
    top: 225px; }
  .container .popover-4 {
    position: absolute;
    left: 70px;
    top: 285px; }
  .container .popover-5 {
    position: absolute;
    right: 350px;
    top: 50px; }
  .container .popover-6 {
    position: absolute;
    right: 570px;
    top: 70px; }
  .container .popover-7 {
    position: absolute;
    left: 405px;
    bottom: 95px; }
  .container .popover-8 {
    position: absolute;
    left: 405px;
    bottom: 30px; }
  .container .popover-9 {
    position: absolute;
    right: 210px;
    top: 70px; }
  .container .popover-10 {
    position: absolute;
    right: 35%;
    top: 275px; }
  .container .popover-11 {
    position: absolute;
    right: 84px;
    top: 260px; }
  .container .popover-12 {
    position: absolute;
    right: 220px;
    top: 420px; }
  .container .popover-13 {
    position: absolute;
    right: 110px;
    top: 60px; }
  .container .not-at-standard-guide-popup {
    background-size: 100% 100%;
    padding-bottom: 140px; }
  .container .popover-14 {
    position: absolute;
    left: 110px;
    top: 46px; }
  .container .popover-15 {
    position: absolute;
    right: 420px;
    bottom: 130px; }
  .container .popover-16 {
    position: absolute;
    left: 75px;
    top: 150px; }
  .container .popover-17 {
    position: absolute;
    left: 75px;
    top: 350px; }
  .container .popover-18 {
    position: absolute;
    left: 325px;
    top: 285px; }
  .container .popover-19 {
    position: absolute;
    left: 295px;
    top: 425px; }
  .container .popover-20 {
    position: absolute;
    right: 505px;
    top: 325px; }
  .container .popover-21 {
    position: absolute;
    right: 36%;
    top: 365px; }
  .container A {
    color: white;
    text-align: center;
    padding-right: 24px;
    float: right;
    margin-top: 18px; }
  .container A:hover {
    color: #FF0117; }
  .container .btn-guide-close:hover {
    color: #fff; }

.header {
  padding: 19px 30px 14px;
  box-shadow: 0 1px 0 0 #dedede;
  border-bottom: 1px solid #dedede;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 1001; }
  .header::after {
    display: block;
    content: "";
    clear: both; }
  @media (max-width: 1024px) {
    .header {
      padding: 15px 20px 13px; } }
  @media (max-width: 767px) {
    .header {
      padding: 15px 16px 13px; } }
  .header__hamburger {
    float: left;
    margin-right: 24px;
    cursor: pointer;
    padding-top: 2px;
    display: none; }
    @media (max-width: 1024px) {
      .header__hamburger {
        display: block;
        padding-top: 4px; } }
  .header__hamburger-icon1 {
    fill: none; }
  .header__hamburger-icon2 {
    fill: #595959; }
  .header__faq-icon {
    float: right;
    margin-right: 10px;
    margin-top: 4px; }
    @media (max-width: 767px) {
      .header__faq-icon .header__help-image {
        width: 25px; } }
  .header__user-login {
    float: right;
    margin-top: 10px;
    margin-left: 40px;
    position: relative; }
    @media (max-width: 767px) {
      .header__user-login {
        margin-left: 10px; }
        .header__user-login .m-only {
          padding-bottom: 6px;
          margin-top: -6px; } }
    .header__user-login .user-name {
      margin-right: 10px; }
      @media (max-width: 767px) {
        .header__user-login .user-name {
          display: none; } }
  .header__user-image.open + .logout-menu {
    display: block; }
  .header .logout-menu {
    display: none;
    width: 155px;
    position: absolute;
    right: 0;
    top: 30px;
    height: 40px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px #dcdcdc;
    border-radius: 3px;
    background-color: #ffffff;
    z-index: 100; }
    @media (max-width: 767px) {
      .header .logout-menu {
        top: 35px; } }
    .header .logout-menu > a {
      display: block;
      padding: 10px 10px;
      color: #1a1a1a;
      text-decoration: none;
      transition: all 0.3s ease; }
      .header .logout-menu > a:hover {
        background: #eeeeee; }
      .header .logout-menu > a:before {
        content: '';
        display: inline-block;
        background: url("../images/logout.svg") no-repeat;
        width: 14px;
        height: 14px;
        background-size: contain;
        vertical-align: middle;
        margin-right: 6px; }
    .header .logout-menu:before {
      content: '';
      border-top: 6px solid transparent;
      display: inline-block;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 8px solid #ffffff;
      float: right;
      margin-top: -14px;
      margin-right: 13px;
      position: relative;
      z-index: 99; }
    .header .logout-menu:after {
      content: '';
      border-top: 6px solid transparent;
      display: inline-block;
      width: 12px;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 8px solid #dcdcdc;
      margin-top: -15px;
      margin-right: 12px;
      position: absolute;
      z-index: 98;
      top: 0;
      right: 0; }
  .header__logo-image {
    margin-top: 5px; }

@media (max-width: 767px) {
  .header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    background: #fff; } }

.sidebar {
  min-width: 100px;
  max-width: 100px;
  min-height: calc(100vh - 70px);
  color: #FFFFFF;
  transition: all 0.3s;
  background: #222;
  height: 100%;
  padding-top: 42px;
  position: fixed;
  left: 0;
  top: 70px;
  z-index: 1000; }
  .sidebar__dart-logo {
    position: absolute;
    bottom: 50px;
    padding-left: 0;
    width: 100%; }
  .sidebar .logo-big {
    display: none; }
  .sidebar .logo-small {
    display: block;
    text-align: center; }

.nav-open .sidebar {
  min-width: 100px;
  max-width: 100px; }

.nav-open .logo-big {
  display: block; }

.nav-open .logo-small {
  display: none; }

.nav-open .sidebar__dart-logo {
  padding-left: 16px; }

@media (max-width: 1024px) {
  .sidebar {
    min-width: 365px;
    max-width: 365px;
    transform: translateX(-365px);
    top: 65px;
    transition: all 0.3s ease; } }
  @media (max-width: 1024px) and (max-width: 767px) {
    .sidebar {
      min-width: 260px;
      max-width: 260px; } }

@media (max-width: 1024px) {
  .logo-big {
    display: block !important; }
  .logo-small {
    display: none !important; }
  .sidebar__dart-logo {
    padding-left: 16px; }
  .nav-open .sidebar {
    transform: translateX(0);
    min-width: 365px;
    max-width: 365px; } }
  @media (max-width: 1024px) and (max-width: 767px) {
    .nav-open .sidebar {
      min-width: 260px;
      max-width: 260px; } }

@media (max-width: 1024px) {
  .nav-open .logo-big {
    display: block; }
  .nav-open .logo-small {
    display: none; } }

@media (max-width: 767px) {
  .sidebar {
    min-width: 210px;
    max-width: 210px;
    padding-top: 10px;
    position: fixed;
    top: 65px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    height: calc(100vh - 71px); }
  .nav-open .sidebar {
    min-width: 210px;
    max-width: 210px; } }

@media (max-height: 600px) {
  .sidebar__dart-logo {
    position: static; } }

.main-wrapper {
  position: relative;
  background-color: #f5f6f7;
  overflow: hidden; }

.nav-open .main-wrapper:after {
  content: '';
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: all 0.3s ease;
  z-index: 100; }

.filter-open .main-wrapper .overlay {
  background: rgba(0, 0, 0, 0.2);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: all 0.3s ease;
  z-index: 100; }

.main-content {
  padding: 30px 30px;
  width: auto;
  max-width: 100%;
  min-height: calc(100vh - 71px);
  transition: all 0.3s;
  margin-left: 100px;
  margin-right: 0;
  margin-top: 70px; }

.page-header {
  border-bottom: 1px solid #dddddd;
  padding-bottom: 15px; }

.nav-open .main-content {
  margin-left: 100px; }

.button-wrapper-mobile {
  box-shadow: 0 2px 11px 2px #d4d4d4;
  background-color: #f5f6f7;
  position: fixed;
  z-index: 99;
  bottom: 0;
  width: 100%;
  padding: 10px;
  left: 0;
  text-align: center; }

.custom-multiselect.not-selected button span:first-child {
  color: gray;
  opacity: 0.5; }

.custom-multiselect-dropdown.customized [class*="__menu-list"] [id*="-option-"] {
  color: #39414d;
  margin: 1px 0; }
  @media (min-width: 1025px) {
    .custom-multiselect-dropdown.customized [class*="__menu-list"] [id*="-option-"]:hover, .custom-multiselect-dropdown.customized [class*="__menu-list"] [id*="-option-"]:active {
      background-color: #f2f2f2;
      color: inherit; } }

.custom-multiselect-dropdown.customized [class*="__menu-list"] [class$="__option--is-selected"] {
  background-color: #e6e6e6; }

.custom-multiselect-dropdown.customized [class*="__menu-list"] [class$="__option--is-focused"] {
  background-color: #f2f2f2;
  color: inherit; }

@media (min-width: 768px) and (max-width: 1024px) {
  .main-content {
    padding: 25px 20px; } }

@media (max-width: 1024px) {
  .main-content {
    width: 100%;
    padding: 25px 20px;
    margin: 65px 0 0 0; }
  .nav-open .main-content {
    margin-left: 0; }
  .filter-open .main-content {
    margin-right: 0; } }

@media (max-width: 767px) {
  .main-content {
    width: 100%;
    padding: 25px 16px 70px;
    margin: 0; }
  .page-header {
    border: none; }
  .main-wrapper {
    margin-top: 63px; }
  .modal-open .button-wrapper-mobile {
    padding-right: 17px; } }

.grid__row:hover .start-assessment {
  visibility: visible;
  opacity: 1;
  filter: alpha(opacity=100); }

.grid .start-assessment {
  position: absolute;
  right: 0;
  padding: 0 20px;
  top: 0;
  height: 100%;
  background: #FFFFFF;
  transition: all 0.3s ease;
  visibility: hidden;
  opacity: 0;
  filter: alpha(opacity=0); }
  .grid .start-assessment .btn-primary {
    position: relative;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    min-width: 166px; }

.grid__status-dot {
  display: block;
  width: 12px;
  height: 12px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  background-color: #58595B;
  margin: 0 auto; }

.grid__status-dot-yellow {
  background-color: #ffcc00; }

.grid__status-dot-red {
  background-color: #eb0a1e; }

.grid__status-dot-green {
  background-color: #16ad0b; }

.grid__mail-icon {
  float: right; }

.status-label-mobile {
  font-size: 12px;
  color: #000000; }

.itemmenu-open .high-z-index {
  z-index: 9999999; }

.item-menu-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999900;
  height: 100%;
  background: rgba(255, 255, 255, 0.2); }

.share-overlay-wrap {
  float: right;
  position: absolute;
  right: 15px;
  top: 14px; }
  .share-overlay-wrap .css-11unzgr.share-to__menu-list {
    max-height: 200px !important; }
  @media (min-width: 1025px) and (max-width: 1140px) {
    .share-overlay-wrap {
      right: 1px; } }
  .share-overlay-wrap .more-menu {
    right: 0px;
    top: 40px; }
  @media (max-width: 991px) {
    .share-overlay-wrap {
      position: absolute;
      right: 20px;
      top: 12px; }
      .share-overlay-wrap .more-menu {
        right: 0;
        top: 45px; }
      .share-overlay-wrap .more-option {
        background: #e6e6e6;
        margin-top: 0; } }
  @media (max-width: 767px) {
    .share-overlay-wrap {
      right: 10px; } }

@media (min-width: 768px) and (max-width: 1400px) {
  .grid__mail-icon {
    margin-right: -15px;
    margin-left: 15px; } }

@media (min-width: 768px) {
  .status-label-mobile {
    display: none; } }

@media (max-width: 991px) {
  .text-md-r {
    text-align: right; }
  .mar-md-b-10 {
    margin-bottom: 10px; }
  .grid__order-md-1 {
    order: 1; }
  .grid__order-md-2 {
    order: 2; }
  .grid__order-md-3 {
    order: 3; }
  .grid__order-md-4 {
    order: 4; }
  .grid__order-md-5 {
    order: 5; }
  .grid__order-md-6 {
    order: 6; }
  .grid__order-md-7 {
    order: 7; } }

@media (max-width: 767px) {
  .grid__status-dot {
    margin: 0 5px 0 0;
    display: inline-block;
    vertical-align: middle; }
  .grid__order-sm-1 {
    order: 1; }
  .grid__order-sm-2 {
    order: 2; }
  .grid__order-sm-3 {
    order: 3; }
  .grid__order-sm-4 {
    order: 4; }
  .grid__order-sm-5 {
    order: 5; }
  .grid__order-sm-6 {
    order: 6; }
  .grid__order-sm-7 {
    order: 7; } }

.main-content-conduct-assessment .share-to__menu-list {
  max-height: 200px !important; }

#inline {
  margin-right: 5px;
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 5px; }

.skeleton_row {
  display: flex;
  margin: 10px;
  background-color: #fffcfc;
  border: solid 1px #e6e6e6; }

#skeletonStatus {
  max-width: 14%;
  margin-right: 5px;
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 5px; }

.container {
  margin: auto; }

#schedule_Location {
  margin-right: 5px;
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 5px;
  margin-left: 15px;
  max-width: 24%; }

.start-tfl-btn {
  display: inline-block;
  margin-right: 24px; }
  @media (max-width: 992px) {
    .start-tfl-btn {
      margin-right: 24px; } }

.long-title {
  position: relative;
  padding-right: 25px; }
  .long-title a {
    position: absolute;
    right: 0;
    top: 5px; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 440px; } }

.page-header-upload-document {
  border: none; }

.add-documents {
  padding: 30px 10px;
  border: 1px solid #dedede;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  background: #FFFFFF;
  margin-bottom: 40px; }
  .add-documents__form {
    margin: 0 auto; }
  .add-documents .inputfile:disabled + .disable:before {
    background: #d8d8d8;
    color: #6A6A6A; }
  .add-documents .form-row {
    margin-right: 0px;
    margin-left: 0px; }
    .add-documents .form-row > [class*=col-] {
      padding-right: 15px;
      padding-left: 15px; }
  .add-documents .disabled-review .custom-multiselect button {
    background-color: #eee; }

.custom-multiselect.upload-sop > label + div button + div {
  width: 100%; }

@media (min-width: 768px) {
  .label-mobile {
    display: none; } }

@media (max-width: 767px) {
  .add-documents {
    padding: 25px 0;
    margin-bottom: 20px; } }

.upload-sop-w {
  background-color: #f5f6f7; }
  .upload-sop-w .main-wrapper {
    overflow: inherit; }

.action__status {
  padding: 6px 10px;
  color: #FFFFFF;
  background: #fff;
  font-size: 11px;
  font-family: 'ToyotaDisplay';
  text-align: center;
  text-transform: uppercase;
  display: inline-block; }
  .action__status--gray {
    border: 1px solid #808080;
    color: #808080; }
  .action__status--yellow {
    border: 1px solid #f79900;
    color: #f79900; }
  .action__status--green {
    border: 1px solid #43a940;
    color: #43a940; }
  .action__status--red {
    border: 1px solid #eb0a1e;
    color: #eb0a1e; }
  .action__status--blue {
    border: 1px solid #4d92e2;
    color: #4d92e2; }
  .action__status.grid__status-dot-yellow {
    color: #555555; }

.hrred {
  position: absolute;
  width: 72%;
  top: 10px;
  border: 1px solid red;
  left: 20px; }

.questions__redhelp-button {
  position: absolute;
  right: -27px;
  top: 42px;
  /*width: 24px;
    height: 13px;*/
  display: inline-block;
  pointer-events: auto; }

.action__priority {
  padding: 2px 10px;
  border-radius: 15px;
  color: #FFFFFF;
  display: block;
  text-align: center; }
  .action__priority--high {
    background: #024ca4; }
  .action__priority--medium {
    background: #4a90e2; }
  .action__priority--low {
    background: #78b6ff; }

.action__priority {
  padding: 2px 10px;
  border-radius: 15px;
  color: #FFFFFF;
  display: block;
  text-align: center; }
  .action__priority--high {
    background: #024ca4; }
  .action__priority--medium {
    background: #4a90e2; }
  .action__priority--low {
    background: #78b6ff; }

.questions__documents-wrapper.clickable {
  cursor: pointer;
  pointer-events: auto; }

@media (max-width: 767px) {
  .action__status,
  .action__priority {
    font-size: 11px;
    padding: 2px 5px; } }

@media (max-width: 1200px) {
  .actions-due-date {
    white-space: normal; } }

/*A new class to display read only elements on review complete*/
.disabled-review {
  pointer-events: none; }

.enabled-review {
  pointer-events: auto !important; }

.enabled-review-btn {
  display: block;
  margin-top: 35px;
  text-align: center; }

.action-review-btn {
  display: block;
  margin-top: 35px;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 43%;
  left: 50%; }

.ReviewCollapseError {
  display: block;
  color: #f8efef;
  background-color: #dd2020;
  text-align: center;
  font-weight: bold;
  margin: 2px; }

.dealership-location {
  color: #777777;
  margin-bottom: 10px; }

.download-template {
  color: #eb0a1e;
  font-size: 14px;
  font-family: 'ToyotaText', sans-serif; }
  .download-template::before {
    content: '';
    height: 15px;
    width: 2px;
    display: inline-block;
    background: #1a1a1a;
    vertical-align: sub;
    margin-right: 10px;
    margin-left: 5px; }
  @media (max-width: 767px) {
    .download-template {
      display: none; } }

.complete-status {
  color: #1a1a1a;
  display: inline-block;
  margin-right: 30px;
  vertical-align: middle; }
  .complete-status > span {
    font-family: 'ToyotaDisplay';
    font-size: 24px;
    font-weight: 700;
    display: inline-block;
    vertical-align: sub;
    margin-left: 5px; }

.score {
  display: inline-block;
  vertical-align: middle;
  color: #1a1a1a; }
  .score > span {
    font-family: 'ToyotaDisplay';
    font-size: 24px;
    font-weight: 700;
    display: inline-block;
    vertical-align: sub;
    margin-left: 5px; }

div.MuiTabs-root {
  min-height: 45px; }

.review-type {
  color: #777777; }

.next-tabs {
  margin-left: auto;
  align-self: center; }

.assessment-title {
  font-size: 24px; }

.assessment-link {
  padding-top: 10px;
  margin-bottom: 5px; }
  .assessment-link a {
    font-size: 14px;
    color: #eb0a1e;
    display: inline-block;
    font-family: 'ToyotaText', sans-serif;
    border-right: 1px solid #1a1a1a;
    padding-right: 5px;
    line-height: 1;
    margin-right: 5px; }
    .assessment-link a:first-child {
      padding-left: 0px; }
    .assessment-link a:last-child {
      border-right: 0; }
  @media (max-width: 1024px) {
    .assessment-link {
      display: none; } }

.upload-review-popup {
  margin: auto; }
  .upload-review-popup .popup__body form .row label {
    font-size: 12px;
    font-family: 'ToyotaText', sans-serif;
    color: #888888;
    display: block; }
  .upload-review-popup .popup__body form .row p {
    font-size: 14px;
    font-family: 'ToyotaText', sans-serif;
    color: #1a1a1a; }

.file-upload {
  position: relative; }
  .file-upload .inputfile + label:before {
    background: #444444; }

.form-control-file {
  padding: 0;
  border: 0;
  margin: 0;
  display: block;
  background: #fafafa;
  height: 40px;
  line-height: 34px;
  border: 1px solid #dcdcdc;
  border-radius: 3px;
  font-size: 12px; }

.upload-sop-icon-a {
  fill: none; }

.upload-sop-icon-b {
  stroke: #444444; }

.upload-sop-icon-c {
  stroke-opacity: 0.9; }

.share:before {
  background: url("../images/share-btn.svg");
  width: 20px;
  height: 20px;
  display: inline-block;
  content: '';
  vertical-align: middle;
  margin-right: 10px;
  background-size: contain; }

.add-photo-a {
  fill: none; }

.add-photo-b {
  fill: #595959;
  stroke-width: 0.5;
  stroke: #595959; }

.SectionHeading {
  font-size: 44px;
  font-weight: 700;
  text-transform: uppercase;
  white-space: nowrap;
  /* you can change these variables to control the border */
  --border-color: #eb0a1e;
  --border-width: 5px;
  --bottom-distance: 0px;
  /* you can increase this */
  color: #666;
  display: inline-block;
  background-image: linear-gradient(var(--border-color), var(--border-color));
  background-size: 0% var(--border-width);
  background-repeat: no-repeat;
  transition: background-size 0.3s;
  margin: 5px 0;
  background-position: 0 calc(100% - var(--bottom-distance)); }

.SectionHeading:hover {
  background-size: 100% var(--border-width); }

.action-icon-a {
  fill: none; }

.action-icon-b {
  stroke: #595959; }

.recomendation-icon-a {
  fill: none; }

.recomendation-icon-b {
  fill: #595959; }

div[class*="MuiTypography-body"] {
  font-family: inherit; }

.recommendation-textarea {
  resize: none;
  max-height: 200px;
  width: 100%; }

.kpi-container {
  float: left;
  margin-bottom: 10px; }
  .kpi-container .kpi-label {
    border-radius: 2px;
    font-size: 12px;
    color: #444444;
    position: relative;
    margin-bottom: 2px;
    padding: 0;
    text-align: left; }
  .kpi-container .kpi-value {
    border: 1px solid #dddddd;
    text-align: left;
    border-radius: 4px;
    color: #777777;
    padding: 0 5px;
    min-width: 50px;
    height: 28px;
    line-height: 26px; }
    .kpi-container .kpi-value.disabled-review, .kpi-container .kpi-value.read-only {
      background: #eeeeee; }
  .kpi-container .kpi-value-max-width40 {
    max-width: 40px; }

.sales-kpi {
  margin-right: 5px; }

.questions__right-col {
  padding-top: 4px; }
  .questions__right-col .btn:last-child {
    margin-right: 0 !important; }
  .questions__right-col .questions__guidance-icon-desktop {
    pointer-events: auto; }
  @media (max-width: 1350px) and (min-width: 1340px) {
    .questions__right-col .btn {
      padding: 8px 15px; } }
  @media (max-width: 1340px) and (min-width: 1025px) {
    .questions__right-col .btn {
      padding: 8px 10px;
      font-size: 12px; } }
  @media (max-width: 1140px) and (min-width: 1025px) {
    .questions__right-col .btn {
      padding: 8px 5px; } }
  .questions__right-col .dropdown-with-search .css-1492t68 {
    opacity: 1;
    color: #1a1a1a; }

.questions__row {
  border-radius: 4px;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  margin-bottom: 10px;
  position: relative; }

.questions__disabled-column {
  display: block;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1); }

.questions__header {
  padding: 20px 60px 20px 20px; }
  @media (max-width: 991px) {
    .questions__header {
      padding: 20px 20px 20px 20px; } }

.questions__toggle-button {
  position: absolute;
  right: 24px;
  top: 32px;
  width: 24px;
  height: 13px;
  display: inline-block;
  background: url(../images/arrow-solid-up.png) no-repeat center center; }
  .questions__toggle-button.collapsed {
    background-image: url(../images/arrow-solid-down.png); }

.questions__detail {
  border-top: 2px solid #e9e9e9;
  background-color: #fafafa; }

.questions__count {
  float: left;
  border: 1px solid #e1e1e1;
  width: 30px;
  height: 30px;
  text-align: center;
  padding-top: 4px;
  font-size: 14px;
  color: #000000;
  background: #eeeeee;
  margin-right: 15px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  margin-right: 18px; }
  .questions__count.active {
    border-color: #e1e1e1; }
  .questions__count.commented {
    border-color: orange;
    background: #fff; }

.questions__question {
  color: #1a1a1a;
  float: left;
  width: calc(100% - 64px);
  white-space: pre-line; }
  @media (max-width: 991px) {
    .questions__question {
      width: calc(100% - 100px); } }

.questions__description {
  padding: 16px 24px; }

.questions__alert-icon-mobile, .questions__guidance-icon-mobile {
  display: none; }

.questions__documents {
  border-top: 1px solid #e4e4e4;
  color: #4a545d;
  font-size: 13px; }
  .questions__documents .docs-header-text {
    font-size: 16px;
    color: #1a1a1a;
    display: inline-block;
    vertical-align: middle; }
  .questions__documents .docs-content {
    margin-top: 20px; }
  .questions__documents .docs-icon {
    margin-right: 8px; }
  .questions__documents .edit-icon {
    margin-right: 8px;
    cursor: pointer; }
  .questions__documents-wrapper {
    padding: 30px;
    border-right: 1px solid #efefef;
    position: relative; }
    .questions__documents-wrapper.last {
      border: none; }
    .questions__documents-wrapper .addMore-doc {
      display: none; }
    .questions__documents-wrapper .addMore__doc-sop {
      right: 18px; }
  .questions__documents .add-more-link {
    float: right;
    color: #eb0a1e;
    text-decoration: underline;
    font-size: 20px;
    cursor: pointer;
    pointer-events: auto; }
  .questions__documents .add-more-link-disabled {
    float: right;
    color: grey;
    text-decoration: underline;
    font-size: 14px;
    cursor: pointer;
    pointer-events: auto; }
  .questions__documents .add-more-link_Sop {
    position: relative;
    left: 12px; }
  .questions__documents .MuiTabs-root {
    min-height: 45px; }
  .questions__documents .MuiTabs-flexContainer {
    justify-content: space-around; }
    .questions__documents .MuiTabs-flexContainer .MuiPrivateTabIndicator-root {
      display: none; }
    .questions__documents .MuiTabs-flexContainer .MuiTab-root {
      flex-grow: initial;
      flex-shrink: initial;
      min-width: 45px;
      min-height: 45px; }
      .questions__documents .MuiTabs-flexContainer .MuiTab-root.MuiTab-selected {
        background-color: #ebebeb; }
        .questions__documents .MuiTabs-flexContainer .MuiTab-root.MuiTab-selected .custom-svg use, .questions__documents .MuiTabs-flexContainer .MuiTab-root.MuiTab-selected .custom-svg g {
          fill: #eb0a1e; }
        .questions__documents .MuiTabs-flexContainer .MuiTab-root.MuiTab-selected .recomendation-icon-b {
          fill: #eb0a1e; }
        .questions__documents .MuiTabs-flexContainer .MuiTab-root.MuiTab-selected .action-icon-b {
          stroke: #eb0a1e; }
        .questions__documents .MuiTabs-flexContainer .MuiTab-root.MuiTab-selected .add-photo-b {
          fill: #eb0a1e;
          stroke: #eb0a1e; }
        .questions__documents .MuiTabs-flexContainer .MuiTab-root.MuiTab-selected .upload-sop-icon-b {
          stroke: #eb0a1e; }
      .questions__documents .MuiTabs-flexContainer .MuiTab-root.error {
        background-color: #eb0a1e; }
        .questions__documents .MuiTabs-flexContainer .MuiTab-root.error .recomendation-icon-b {
          fill: #FFFFFF; }
        .questions__documents .MuiTabs-flexContainer .MuiTab-root.error .action-icon-b {
          stroke: #FFFFFF; }
        .questions__documents .MuiTabs-flexContainer .MuiTab-root.error .add-photo-b {
          fill: #FFFFFF;
          stroke: #FFFFFF; }
        .questions__documents .MuiTabs-flexContainer .MuiTab-root.error .upload-sop-icon-b {
          stroke: #FFFFFF; }

.questions__recomendation-content {
  word-break: break-word;
  max-height: 80px;
  overflow-x: auto;
  padding-right: 5px;
  pointer-events: all; }

.questions__photo-documents {
  padding-left: 12px; }
  @media (max-width: 1024px) {
    .questions__photo-documents {
      padding-left: 30px; } }

.questions__supporting-sop .sop-row {
  position: relative;
  padding-left: 40px;
  padding-right: 30px;
  margin-bottom: 15px; }
  .questions__supporting-sop .sop-row .sop-icon {
    float: left;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1; }
    .questions__supporting-sop .sop-row .sop-icon .uploaded-icon {
      position: absolute;
      top: -3px;
      right: -1px; }
  .questions__supporting-sop .sop-row .sop-action-icons {
    position: absolute;
    right: 0;
    top: 0; }
  .questions__supporting-sop .sop-row .upload-progress {
    background-color: #efefef;
    width: 100%;
    height: 2px;
    margin-top: 2px; }
    .questions__supporting-sop .sop-row .upload-progress .upload-progress-bar {
      height: 100%;
      background-color: #757575; }
  .questions__supporting-sop .sop-row.uploading .uploaded-icon {
    display: none; }
  .questions__supporting-sop .sop-row.uploading .remove-sop {
    display: none; }
  .questions__supporting-sop .sop-row.uploaded .upload-progress {
    display: none; }
  .questions__supporting-sop .sop-row.uploaded .cancel-upload {
    display: none; }
  .questions__supporting-sop .sop-row.uploaded .sop-name {
    padding-top: 3px; }

.questions__photo-documents .document-list > ul {
  width: 100%;
  max-height: 150px; }
  @media (max-width: 767px) {
    .questions__photo-documents .document-list > ul {
      margin-bottom: 20px; } }

.questions__photo-documents .document-list .file-name {
  width: calc(100% - 60px);
  text-overflow: ellipsis; }

.questions__photo-documents .image-box {
  display: inline-block;
  margin: 0 6px 11px;
  position: relative; }
  .questions__photo-documents .image-box .image-icon {
    display: block;
    margin-bottom: 5px; }
  .questions__photo-documents .image-box .selected-icon {
    display: none;
    position: absolute;
    right: -5px;
    top: -5px; }
  .questions__photo-documents .image-box.selected .selected-icon {
    display: block; }

.questions__photo-documents .docs-content {
  position: relative; }
  .questions__photo-documents .docs-content .download-icon {
    padding: 5px;
    right: 2px;
    top: 0; }
  .questions__photo-documents .docs-content .delete-icon {
    padding: 5px;
    right: 0;
    top: 22px;
    cursor: pointer; }

.questions__action {
  border: 1px solid #eb0a1e !important; }
  .questions__action .action-header .docs-header-text {
    color: #eb0a1e; }
  .questions__action p {
    margin-bottom: 8px; }
  .questions__action .action-details {
    font-size: 12px;
    color: #777777; }
    .questions__action .action-details .row {
      margin-bottom: 4px; }

.questions__comment-box {
  background: #fff;
  padding: 15px 20px; }
  @media (max-width: 767px) {
    .questions__comment-box {
      padding: 15px 12px; }
      .questions__comment-box .p-r-0 {
        padding-right: 15px !important; }
      .questions__comment-box .m-text-center {
        text-align: center; } }
  .questions__comment-box textarea.form-control {
    min-height: 50px;
    margin-bottom: 0; }
    @media (max-width: 767px) {
      .questions__comment-box textarea.form-control {
        margin-bottom: 15px; } }

.questions__comment-history {
  max-height: 150px;
  overflow-x: hidden;
  overflow-y: auto;
  padding-right: 10px; }
  .questions__comment-history label {
    color: #000000;
    font-size: 14px; }
  .questions__comment-history .comment-username {
    color: #000000; }
  .questions__comment-history .comment-history-date {
    color: #888888;
    font-size: 12px; }
  .questions__comment-history .comment-description {
    color: #777777; }
  .questions__comment-history .comment-listing {
    border-bottom: 1px solid #e6e6e6;
    margin-bottom: 10px; }
    .questions__comment-history .comment-listing p {
      margin-bottom: 10px; }
    .questions__comment-history .comment-listing:last-child {
      border-bottom: none; }

.questions .flag-icon {
  float: right;
  margin-top: 5px;
  margin-left: 10px;
  margin-right: 10px; }
  @media (max-width: 1340px) and (min-width: 1025px) {
    .questions .flag-icon {
      margin-left: 5px;
      margin-right: 5px; }
      .questions .flag-icon img {
        width: 22px; } }
  @media (max-width: 767px) {
    .questions .flag-icon {
      float: none;
      display: inline-block;
      vertical-align: middle;
      margin-top: 0; } }
  .questions .flag-icon .active {
    display: none; }
  .questions .flag-icon.active-flag .active {
    display: block; }
  .questions .flag-icon.active-flag .in-active {
    display: none; }

.review-buttons-mobile {
  padding: 0 10px; }
  .review-buttons-mobile .save-btn {
    margin-bottom: 10px;
    border-bottom: 1px solid #d8d8d8;
    padding: 0 10px 10px;
    margin-left: -10px;
    margin-right: -10px; }

.responseButton {
  display: inline-block; }

.btn-blank.at-standard-btn.AS:not(:disabled):not(.disabled).active,
.btn-blank.at-standard-btn.AS:not(:disabled):not(.disabled):active {
  background: #21c500;
  border-color: #444444; }

.btn-blank.at-standard-btn.NS:not(:disabled):not(.disabled).active,
.btn-blank.at-standard-btn.NS:not(:disabled):not(.disabled):active {
  background: #eb0a1e;
  border-color: #444444; }

.btn-blank.not-standard-btn:not(:disabled):not(.disabled).active,
.btn-blank.not-standard-btn:not(:disabled):not(.disabled):active {
  background: #eb0a1e;
  border-color: #eb0a1e; }

.response-dropdown {
  display: inline-block;
  margin-right: 10px;
  pointer-events: auto; }
  @media (max-width: 768px) {
    .response-dropdown {
      float: left; } }
  .response-dropdown > div:first-child > .css-vj8t7z, .response-dropdown > div:first-child > .css-2o5izw {
    border-radius: 40px;
    border-color: #dbdbdb; }
    .response-dropdown > div:first-child > .css-vj8t7z > div > span, .response-dropdown > div:first-child > .css-2o5izw > div > span {
      display: none; }
    .response-dropdown > div:first-child > .css-vj8t7z svg, .response-dropdown > div:first-child > .css-2o5izw svg {
      display: none; }
  .response-dropdown .css-xp4uvy, .response-dropdown .css-1492t68 {
    position: static;
    top: auto;
    transform: none;
    max-width: 110px;
    min-width: 50px;
    text-align: center; }
  .response-dropdown .css-1g6gooi input {
    width: 0 !important; }
  .response-dropdown .css-15k3avv {
    text-align: left;
    margin-top: 10px;
    z-index: 100;
    overflow: hidden; }
    .response-dropdown .css-15k3avv:before {
      content: '';
      border-top: 6px solid transparent;
      display: inline-block;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 8px solid #ffffff;
      float: right;
      margin-top: -14px;
      margin-right: 13px;
      position: relative;
      z-index: 99; }
    .response-dropdown .css-15k3avv:after {
      content: '';
      border-top: 6px solid transparent;
      display: inline-block;
      width: 12px;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 8px solid #dcdcdc;
      margin-top: -15px;
      margin-right: 12px;
      position: absolute;
      z-index: 98;
      top: 0;
      right: 0; }
    .response-dropdown .css-15k3avv .css-11unzgr {
      padding-bottom: 0;
      padding-top: 0; }
      .response-dropdown .css-15k3avv .css-11unzgr .css-wqgs6e {
        background-color: #eeeeee; }
      .response-dropdown .css-15k3avv .css-11unzgr .css-z5z6cw {
        background-color: #444444;
        color: #fff; }
  .response-dropdown .css-1ep9fjw, .response-dropdown .css-1uq0kb5 {
    background: url("../images/down-selected.png") no-repeat right 0.75rem center;
    width: 28px; }
  .response-dropdown__selected > div:first-child > .css-vj8t7z, .response-dropdown__selected > div:first-child > .css-2o5izw {
    background: #444444; }
  .response-dropdown__selected .css-xp4uvy {
    color: #fff; }
  .response-dropdown__selected .css-1ep9fjw, .response-dropdown__selected .css-1uq0kb5 {
    background: url("../images/down-selected-white.png") no-repeat right 0.75rem center;
    width: 28px; }

.css-vj8t7z {
  border-color: #dcdcdc !important; }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .response-dropdown .css-1hwfws3 {
    flex: 0 auto;
    padding: 4px 8px; }
  .add-documents .css-1hwfws3 {
    padding: 6px 8px; }
  .questions__alert-icon-desktop, .questions__guidance-icon-desktop {
    display: inline-block;
    vertical-align: top;
    margin-top: 10px;
    width: 18px; } }

@media (min-width: 768px) and (max-width: 1199px) {
  .questions__documents-wrapper {
    border-bottom: 1px solid #efefef; }
    .questions__documents-wrapper:nth-child(odd) {
      border-right: 1px solid #efefef; }
    .questions__documents-wrapper:nth-child(even) {
      border-right: none; } }

@media (min-width: 768px) {
  .questions__documents {
    padding-left: 15px;
    padding-right: 15px; } }

@media (min-width: 768px) and (max-width: 990px) {
  .at-standard-btn, .not-standard-btn {
    padding-left: 10px;
    padding-right: 10px; }
  .at-standard-btn,
  .questions__alert-icon-desktop {
    margin-right: 10px !important; } }

@media (max-width: 767px) {
  .questions__toggle-button {
    width: 16px;
    height: 8px;
    background-size: cover;
    top: 16px;
    right: 10px; }
  .questions__header {
    padding: 12px; }
  .questions__right-col {
    text-align: left !important;
    margin-top: 12px; }
  .questions__left-col {
    padding-right: 30px; }
  .questions__image {
    width: 18px; }
  .questions__documents [class*="MuiAppBar-root"].MuiAppBar-colorPrimary {
    background: none;
    border-bottom: 1px solid #e4e4e4; }
  .questions__documents-wrapper {
    padding: 15px 18px; }
  .questions .btn {
    font-size: 13px; }
  .questions__alert-icon-mobile {
    display: block !important;
    position: absolute;
    right: 9px;
    top: 65px; }
  .questions__guidance-icon-mobile {
    display: block !important;
    position: absolute;
    right: -3px;
    top: 30px; }
  .questions__alert-icon-desktop {
    display: none; }
  .questions__guidance-icon-desktop {
    display: none; }
  .tab-content {
    padding-left: 0 !important;
    padding-right: 0 !important; }
    .tab-content h4 {
      font-size: 14px;
      margin: 10px 0 18px !important; }
  .page-header-conduct-assessment h2 {
    font-size: 18px; }
  .page-header-conduct-assessment h6 {
    font-size: 15px; }
  .review-type {
    font-size: 12px; }
  .dealership-location {
    font-size: 12px; }
  .nav-tabs .nav-link img {
    width: 12px; }
  .button-wrapper-mobile {
    padding-left: 0;
    padding-right: 0; }
  .main-content-conduct-assessment {
    padding-bottom: 130px; }
  .kpi-container {
    margin-top: 20px;
    margin-left: 50px; } }

@media (max-width: 374px) {
  .btn.at-standard-btn {
    margin-right: 10px !important; }
  .questions .btn {
    font-size: 12px; }
  .questions .at-standard-btn, .questions .not-standard-btn {
    padding-left: 10px;
    padding-right: 10px; } }

.truncate .MuiTab-labelContainer {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; }

.response-open {
  background: #f5f6f7; }
  .response-open .main-wrapper {
    overflow: inherit; }

@media (min-width: 1440px) {
  .m-r-20-l {
    margin-right: 20px !important; } }

.delete-popup {
  margin: auto; }
  .delete-popup .delete-warning {
    width: 60px;
    height: 60px;
    background: #eb0a1e;
    border-radius: 50%;
    line-height: 56px;
    margin: auto;
    text-align: center; }
  .delete-popup .text-red:hover {
    color: #eb0a1e !important; }

.main-content-skeleton {
  padding: 30px 30px;
  width: auto;
  max-width: 100%;
  min-height: calc(100vh - 71px);
  transition: all 0.3s;
  margin-left: 100px;
  margin-right: 0;
  margin-top: 70px; }

@media (min-width: 768px) and (max-width: 1024px) {
  .main-content-skeleton {
    padding: 25px 20px; } }

@media (max-width: 1024px) {
  .main-content-skeleton {
    width: 100%;
    padding: 25px 20px;
    margin: 65px 0 0 0; }
  .nav-open .main-content {
    margin-left: 0; }
  .filter-open .main-content {
    margin-right: 0; } }

#download_review_skeleton {
  max-width: 110px;
  min-width: 110px;
  margin-right: 10px; }

#upload_review_skeleton {
  max-width: 91.4px;
  min-width: 91.4px; }

#download-skeleton-container {
  display: flex;
  padding-right: 5px;
  margin-bottom: 5px; }

.location_skeleton {
  margin-bottom: 9.35px;
  /* min-width: 350px; */ }

#skeleton-button-container {
  display: flex;
  justify-content: right; }

#skeleton-button-container_bottom {
  display: flex;
  justify-content: center; }

.btn-skeleton {
  margin-right: 20px;
  min-width: 100px;
  border-radius: 26px;
  min-height: 40px; }

.linear {
  height: 2.8vh;
  position: fixed;
  bottom: 0px;
  width: calc(100% - 160px);
  color: black;
  border: 1px #8AC007; }

#SkeletonSectionNameTFL {
  max-width: 25%;
  background-color: #f5e2e2; }

.sectionNameContainer {
  display: flex; }

#SkeletonSectionName {
  min-width: 10%;
  background-color: #f5e2e2;
  margin-right: 10px; }

#SkeletonCriteria_Name {
  max-width: 35%;
  min-width: 35%; }

.skeleton_container {
  display: flex;
  justify-content: space-between; }

.skeleton_questions__count {
  float: left;
  width: 30px;
  height: 30px;
  text-align: center;
  padding-top: 4px;
  font-size: 14px;
  color: #000000;
  border-radius: 4px;
  margin-right: 18px; }

.Skeleton_button_container {
  display: flex;
  justify-content: right; }

#Skeleton_notStandard {
  min-width: 140px;
  background-color: #f5e2e2; }

#Skeleton_Standard {
  background-color: #e2f5df;
  min-width: 115px; }

#Skeleton_Fail {
  min-width: 64px;
  background-color: #f5e2e2; }

#Skeleton_Pass {
  background-color: #e2f5df;
  min-width: 70px; }

#Skeleton_Response_DPOK {
  min-width: 150px; }

#skeleton_delete {
  min-width: 82px; }

#skeleton_save {
  min-width: 72px; }

#skeleton_save_review {
  min-width: 120px; }

#skeleton_approve {
  min-width: 120px;
  background-color: #f5e2e2; }

#skeleton_saveAndclose {
  min-width: 120px; }

#skeleton_submit {
  min-width: 130px; }

#skeleton_export {
  min-width: 95px; }

#skeleton_score {
  background-color: #f5e2e2;
  min-width: 120px; }

#skeleton_share {
  background-color: #f5e2e2;
  min-width: 120px; }

.evidence-upload {
  /*background-color: #ffeff1;*/
  margin: 0 -24px;
  padding: 12px 24px; }

#closing-Comment-Evidence {
  font-size: 12px;
  color: #222222;
  margin-bottom: 5px; }

.priority-buttons-wrapper input[type="radio"] {
  display: none; }
  .priority-buttons-wrapper input[type="radio"].first:not(:disabled):not(.disabled):checked + label {
    background-color: #024ca4;
    color: #FFFFFF; }
  .priority-buttons-wrapper input[type="radio"]:not(:disabled):not(.disabled):checked + label {
    background-color: #4a90e2;
    color: #FFFFFF; }
  .priority-buttons-wrapper input[type="radio"].last:not(:disabled):not(.disabled):checked + label {
    background-color: #78b6ff;
    color: #FFFFFF; }
  .priority-buttons-wrapper input[type="radio"] + label {
    display: block;
    margin: 0;
    float: left;
    border-radius: 0;
    background: #FFFFFF;
    border: 1px solid #dcdcdc;
    color: #6a6a6a;
    width: 100%;
    padding: 10px 0;
    font-size: 14px;
    cursor: pointer; }
  .priority-buttons-wrapper input[type="radio"].first + label {
    -webkit-border-radius: 3px 0 0 3px;
    -moz-border-radius: 3px 0 0 3px;
    -ms-border-radius: 3px 0 0 3px;
    border-radius: 3px 0 0 3px; }
  .priority-buttons-wrapper input[type="radio"].last + label {
    -webkit-border-radius: 0 3px 3px 0;
    -moz-border-radius: 0 3px 3px 0;
    -ms-border-radius: 0 3px 3px 0;
    border-radius: 0 3px 3px 0; }

.priority-buttons-wrapper .form-check {
  padding: 0;
  text-align: center;
  width: 33.3%;
  float: left; }

.status-buttons-wrapper input[type="radio"] {
  display: none; }
  .status-buttons-wrapper input[type="radio"].first:not(:disabled):not(.disabled):checked + label {
    background-color: #FFA500;
    color: #FFFFFF; }
  .status-buttons-wrapper input[type="radio"].second:not(:disabled):not(.disabled):checked + label {
    background-color: #ffcc00;
    color: #FFFFFF; }
  .status-buttons-wrapper input[type="radio"].third:not(:disabled):not(.disabled):checked + label {
    background-color: #16ad0b;
    color: #FFFFFF; }
  .status-buttons-wrapper input[type="radio"].last:not(:disabled):not(.disabled):checked + label {
    background-color: #eb0a1e;
    color: #FFFFFF; }
  .status-buttons-wrapper input[type="radio"] + label {
    display: block;
    margin: 0;
    float: left;
    border-radius: 0;
    background: #FFFFFF;
    border: 1px solid #dcdcdc;
    color: #6a6a6a;
    width: 100%;
    padding: 10px 0;
    font-size: 14px;
    cursor: pointer; }
  .status-buttons-wrapper input[type="radio"].first + label {
    -webkit-border-radius: 3px 0 0 3px;
    -moz-border-radius: 3px 0 0 3px;
    -ms-border-radius: 3px 0 0 3px;
    border-radius: 3px 0 0 3px; }
  .status-buttons-wrapper input[type="radio"].last + label {
    -webkit-border-radius: 0 3px 3px 0;
    -moz-border-radius: 0 3px 3px 0;
    -ms-border-radius: 0 3px 3px 0;
    border-radius: 0 3px 3px 0; }

.status-buttons-wrapper.hide-complete input[type="radio"] + label {
  width: 100%; }
  .status-buttons-wrapper.hide-complete input[type="radio"] + label.completeStatus {
    display: none; }

.status-buttons-wrapper .form-check {
  padding: 0;
  text-align: center;
  width: 25%;
  float: left; }

.status-buttons-wrapper .form-status-check {
  padding: 0;
  text-align: center;
  width: 33.3%;
  float: left; }

.text-limit-evidence {
  position: absolute;
  right: 2px;
  top: 3px; }

.upload-document-link {
  color: #000000; }
  .upload-document-link:hover {
    color: #000000; }

.uploaded-documents-list li {
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  color: #777777;
  margin-bottom: 15px;
  float: left;
  width: 100%; }
  .uploaded-documents-list li .filename {
    float: left;
    max-width: calc(100% - 60px);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; }

.uploaded-documents-list + .form-group {
  float: left;
  width: 100%; }

@media (max-width: 767px) {
  .evidence-upload {
    margin: 0 -20px;
    padding: 12px 20px; }
  .status-buttons-wrapper input[type="radio"] + label,
  .priority-buttons-wrapper input[type="radio"] + label {
    font-size: 12px; } }

@media (max-width: 374px) {
  .status-buttons-wrapper input[type="radio"] + label {
    font-size: 12px;
    width: 100%; }
  .status-buttons-wrapper input[type="radio"].first + label {
    -webkit-border-radius: 3px 3px 0 0;
    -moz-border-radius: 3px 3px 0 0;
    -ms-border-radius: 3px 3px 0 0;
    border-radius: 3px 3px 0 0; }
  .status-buttons-wrapper input[type="radio"].last + label {
    -webkit-border-radius: 0 0 3px 3px;
    -moz-border-radius: 0 0 3px 3px;
    -ms-border-radius: 0 0 3px 3px;
    border-radius: 0 0 3px 3px; }
  .status-buttons-wrapper .form-check {
    width: 100%; }
  .priority-buttons-wrapper input[type="radio"] + label {
    font-size: 12px;
    width: 100%; }
  .priority-buttons-wrapper input[type="radio"].first + label {
    -webkit-border-radius: 3px 3px 0 0;
    -moz-border-radius: 3px 3px 0 0;
    -ms-border-radius: 3px 3px 0 0;
    border-radius: 3px 3px 0 0; }
  .priority-buttons-wrapper input[type="radio"].last + label {
    -webkit-border-radius: 0 0 3px 3px;
    -moz-border-radius: 0 0 3px 3px;
    -ms-border-radius: 0 0 3px 3px;
    border-radius: 0 0 3px 3px; }
  .priority-buttons-wrapper .form-check {
    width: 100%; }
  .due-date,
  .creation-date {
    flex: 0 0 100%;
    max-width: 100%; } }

.add-more-link {
  float: right;
  color: #eb0a1e;
  text-decoration: underline;
  font-size: 14px; }

.success-content-wrapper h6 {
  color: #707070; }

@media (max-width: 767px) {
  .success-content-wrapper h1 {
    font-size: 30px; }
  .success-content-wrapper h6 {
    font-size: 12px; } }

.faq-banner {
  background: url(../images/male-with-bald-hair-side-view.svg) no-repeat center center;
  background-size: cover;
  text-align: center;
  margin: -30px -40px 28px;
  padding: 45px; }
  .faq-banner h3 {
    color: #FFFFFF; }

.faq-content {
  max-width: 1164px;
  margin: 0 auto; }
  .faq-content .guided-learning-btn {
    font-size: 12px; }

.faq {
  padding: 48px 0; }
  .faq__row {
    border-radius: 4px;
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    margin-bottom: 10px;
    position: relative; }
  .faq__header {
    padding: 12px 50px 12px 16px; }
  .faq__toggle-button:before {
    content: '';
    background-image: url(../images/arrow-solid-down.png);
    width: 24px;
    height: 13px;
    position: absolute;
    right: 18px;
    top: 22px; }
  .faq__collapse.show ~ .faq__toggle-button:before {
    transform: rotate(-180deg); }
  .faq__detail {
    border-top: 2px solid #e9e9e9;
    color: #222222;
    padding: 22px 30px; }
  .faq__count {
    float: left;
    border: 2px solid #dedede;
    width: 28px;
    height: 28px;
    background-color: #efefef;
    text-align: center;
    padding-top: 2px;
    font-size: 14px;
    color: #000000;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    margin-right: 16px; }
  .faq__question {
    color: #595959;
    float: left;
    width: calc(100% - 44px);
    padding-top: 5px; }
    @media (max-width: 767px) {
      .faq__question {
        padding-top: 0; } }

.error-main-content {
  background: url(../images/error-bg.svg) no-repeat center center #FFFFFF;
  color: #2d2d2d; }

.logout-screen {
  background: #fff !important;
  padding-top: 120px; }
  @media (max-width: 767px) {
    .logout-screen {
      padding-top: 30px; } }
  .logout-screen .login__box {
    background: url("../images/logout.png") no-repeat;
    background-position: center center;
    background-size: contain;
    max-width: 450px;
    margin: auto;
    min-height: 370px; }
    .logout-screen .login__box .login-content {
      position: absolute;
      bottom: 30px;
      left: 50%;
      transform: translate(-50%, 0);
      width: 100%; }

.login__button {
  min-width: 95px;
  max-width: 95px; }

.login-page {
  padding: 50px 160px;
  background: #FFFFFF;
  height: 100vh;
  max-width: 1440px;
  margin: auto; }
  .login-page:before {
    content: '';
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    height: 100%;
    z-index: 0;
    background: #fff; }
  @media (max-width: 1330px) and (min-width: 992px) {
    .login-page {
      padding: 50px 20px; } }
  @media (max-width: 991px) {
    .login-page {
      padding: 50px 15px;
      height: auto; }
      .login-page .h-100 {
        height: auto !important; } }
  @media (max-width: 767px) {
    .login-page {
      padding: 0 15px; } }
  .login-page__head {
    font-family: 'ToyotaDisplay';
    font-size: 45px;
    font-weight: bold;
    font-style: normal;
    color: #000000; }
  .login-page__form {
    max-width: 470px;
    margin: auto;
    background: #FFFFFF;
    margin-top: 5px; }
    @media (max-width: 991px) {
      .login-page__form {
        padding-bottom: 0;
        margin-top: 50px; } }
    @media (max-width: 767px) {
      .login-page__form {
        padding-bottom: 125px;
        margin-top: 5px; } }
    .login-page__form:before {
      content: '';
      position: absolute;
      width: 1px;
      height: 250px;
      background: #979797;
      left: 0;
      top: 50%;
      margin-top: -125px; }
      @media (max-width: 991px) {
        .login-page__form:before {
          display: none; } }
  .login-page__form-control {
    padding-top: 50px; }
    @media (max-width: 767px) {
      .login-page__form-control {
        padding-top: 15px; } }
    .login-page__form-control .btn {
      width: 100%;
      margin-top: 30px !important; }
  @media (max-width: 991px) {
    .login-page__container {
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
      padding: 60px 30px;
      border-radius: 6px; } }
  @media (max-width: 767px) {
    .login-page__container {
      padding: 30px 15px 0; }
      .login-page__container img {
        max-width: 85px; } }
  @media (max-width: 767px) {
    .login-page__msg {
      position: fixed;
      bottom: 25px;
      width: 100%;
      padding: 0 15px;
      left: 0;
      margin: 0; } }

@media (max-width: 1024px) {
  .tfl-score {
    display: none; } }

.error-screen {
  display: none; }
  @media (max-width: 1024px) {
    .error-screen {
      display: block; } }

.override {
  text-decoration: underline;
  padding-left: 10px;
  cursor: pointer; }

.more-option {
  float: right;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  overflow: hidden;
  transition: 0.3s ease;
  text-align: center;
  margin-top: -5px;
  margin-left: 10px;
  cursor: pointer; }
  .more-option:hover {
    background: #e6e6e6; }
  .more-option img {
    margin-top: 5px; }
  .more-option.open {
    background: #e6e6e6; }
    .more-option.open + .more-menu {
      display: block; }

.location-name-truncate {
  padding-left: 38px;
  cursor: pointer; }

.grid__sapcode {
  cursor: pointer; }

.more-menu {
  display: none;
  width: 155px;
  position: absolute;
  right: 14px;
  top: 43px;
  z-index: 100; }
  .more-menu ul {
    padding: 0;
    margin: 0;
    overflow: hidden;
    border-radius: 3px;
    border: solid 1px #dcdcdc;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
    text-align: left; }
    .more-menu ul li {
      display: block;
      list-style: none;
      padding: 10px 10px;
      color: #1a1a1a;
      text-decoration: none;
      transition: all 0.3s ease;
      cursor: pointer; }
      .more-menu ul li.approve:before {
        content: '';
        display: inline-block;
        background: url("../images/approve.svg") no-repeat;
        width: 14px;
        height: 14px;
        background-size: contain;
        vertical-align: middle;
        margin-right: 6px; }
      .more-menu ul li.reset:before {
        content: '';
        display: inline-block;
        background: url("../images/reset.svg") no-repeat;
        width: 16px;
        height: 16px;
        background-size: contain;
        vertical-align: middle;
        margin-right: 6px; }
      .more-menu ul li.sharereview:before {
        content: '';
        display: inline-block;
        background: url("../images/icons-share.svg") no-repeat;
        width: 16px;
        height: 16px;
        background-size: contain;
        vertical-align: middle;
        margin-right: 6px; }
      .more-menu ul li:nth-child(odd) {
        background: #fff; }
      .more-menu ul li:nth-child(even) {
        background: #eeeeee; }
  .more-menu .menu-wraper:before {
    content: '';
    border-top: 6px solid transparent;
    display: inline-block;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 8px solid #ffffff;
    position: absolute;
    z-index: 99;
    right: 13px;
    top: -12px; }
  .more-menu .menu-wraper:after {
    content: '';
    border-top: 6px solid transparent;
    display: inline-block;
    width: 12px;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 8px solid #dcdcdc;
    margin-top: -13px;
    margin-right: 12px;
    position: absolute;
    z-index: 98;
    top: 0;
    right: 0; }

.total-score {
  font-size: 16px; }
  .total-score b {
    font-size: 24px;
    font-family: 'ToyotaDisplay';
    margin-left: 10px; }

.tflscore-popup__left-col {
  margin-top: 30px;
  margin-bottom: 30px;
  padding-right: 25px;
  max-height: 600px;
  overflow-x: auto; }

.tflscore-popup__right-col {
  padding-left: 40px;
  height: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
  width: 100%;
  float: left; }
  .tflscore-popup__right-col:before {
    content: '';
    position: absolute;
    width: 1px;
    background: #d8d8d8;
    height: 100%;
    left: 0;
    top: 0; }

.tflscore-popup .popup__wrapper {
  max-width: 910px; }

.tflscore-popup .popup__close {
  right: 35px; }

.tflscore-popup .popup__body {
  padding: 0 30px; }

.tflscore-popup .popup__labels {
  font-size: 12px;
  line-height: 1.67;
  color: #888888;
  display: block; }

.tflscore-popup .popup__data {
  color: #1a1a1a; }

.tflscore-popup .popup__date {
  color: #1a1a1a;
  font-family: 'ToyotaDisplay', sans-serif;
  font-weight: bold;
  font-size: 12px; }

.tflscore-popup .popup__history-data {
  padding-left: 90px;
  position: relative;
  padding-bottom: 20px;
  margin-bottom: 10px; }
  .tflscore-popup .popup__history-data::before {
    content: '';
    position: absolute;
    width: 1px;
    background: #d8d8d8;
    height: 100%;
    left: 35px;
    top: 0; }

.tflscore-popup .popup .main-content {
  padding-left: 10px;
  padding-right: 10px; }

.tflscore-popup .photos-document {
  width: 100%;
  float: left;
  margin-top: 15px; }
  .tflscore-popup .photos-document .photo-icon {
    float: left; }
    .tflscore-popup .photos-document .photo-icon > span {
      margin-left: 15px;
      font-size: 16px;
      color: #1a1a1a;
      font-family: 'ToyotaText', sans-serif;
      display: inline-block;
      vertical-align: middle; }
  .tflscore-popup .photos-document .add-more {
    float: right;
    margin-top: 3px;
    color: #eb0a1e;
    text-decoration: underline;
    cursor: pointer;
    font-size: 14px; }

.tflscore-popup .save-btn {
  width: 100%;
  float: left; }
  .tflscore-popup .save-btn .btn-primary {
    min-width: 70px; }

.document-list {
  float: left;
  width: 100%;
  position: relative;
  margin-top: 20px;
  min-height: 50px; }
  .document-list > ul {
    padding: 0 5px 0 0;
    margin: 0;
    width: calc(100% - 20px);
    overflow-x: auto;
    max-height: 120px; }
    .document-list > ul > li {
      list-style: none;
      display: block;
      background: #fff;
      padding: 11px 14px 11px 50px;
      position: relative;
      min-height: 40px; }
      .document-list > ul > li:nth-child(odd) {
        background: #f3f2f1; }
  .document-list .custom-checkbox {
    padding-left: 18px;
    height: 18px; }
    .document-list .custom-checkbox .custom-checkmark {
      border-radius: 10px;
      border: 1px solid #1a1a1a; }
  .document-list .custom-checkbox input:checked ~ .custom-checkmark {
    border: 1px solid transparent; }
  .document-list .image-icon:before {
    background: url("../images/f-image.svg") no-repeat;
    width: 20px;
    height: 20px;
    content: '';
    position: absolute;
    left: 15px;
    top: 10px;
    padding-left: 20px;
    opacity: 0.5;
    background-size: contain; }
  .document-list .doc-icon:before {
    background: url("../images/p-image.svg") no-repeat;
    width: 20px;
    height: 20px;
    content: '';
    position: absolute;
    left: 15px;
    top: 10px;
    padding-left: 20px;
    opacity: 0.5;
    background-size: contain; }
  .document-list .download-icon {
    position: absolute;
    right: 40px;
    top: 12px; }
  .document-list .delete-icon {
    position: absolute;
    top: 12px;
    right: 10px;
    cursor: pointer; }
  .document-list .file-name {
    width: 175px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block; }
  .document-list .fixed-btns .download-icon {
    position: absolute;
    right: 0;
    top: 0; }
  .document-list .fixed-btns .delete-icon {
    position: absolute;
    bottom: 10px;
    right: 0;
    top: auto; }
  .document-list .action-btns {
    float: right;
    width: 50px;
    min-height: 25px; }

@media (max-width: 1024px) {
  .investigation {
    display: none; }
    .investigation ~ div {
      display: none !important; } }

.investigation .photos-document {
  width: 100%;
  float: left;
  margin-top: 15px;
  margin-bottom: 15px; }
  .investigation .photos-document .photo-icon {
    float: left; }
    .investigation .photos-document .photo-icon > span {
      margin-left: 15px;
      font-size: 14px;
      color: #1a1a1a;
      font-family: 'ToyotaText', sans-serif;
      display: inline-block;
      vertical-align: middle; }
  .investigation .photos-document .add-more {
    float: right;
    margin-top: 3px;
    color: #eb0a1e;
    text-decoration: underline;
    cursor: pointer;
    font-size: 14px; }

.investigation .document-list {
  float: left;
  width: 100%;
  position: relative;
  margin-top: -15px !important;
  min-height: 50px;
  padding-bottom: 15px; }
  .investigation .document-list > ul {
    padding: 0  0;
    margin: 0;
    width: 100%;
    overflow-x: auto;
    max-height: 120px; }
    .investigation .document-list > ul > li {
      list-style: none;
      display: block;
      padding: 10px 0 5px 0;
      position: relative;
      min-height: 40px;
      text-align: left;
      border-bottom: 1px solid  #f1f1f1; }
      .investigation .document-list > ul > li:last-child {
        border-bottom: none; }
  .investigation .document-list .download-icon {
    position: absolute;
    right: 35px;
    top: 13px; }
  .investigation .document-list .delete-icon {
    position: absolute;
    top: 12px;
    right: 5px; }
  .investigation .document-list .file-name {
    width: calc(100% - 70px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    padding-left: 5px; }
  .investigation .document-list .fixed-btns .download-icon {
    position: absolute;
    right: 0;
    top: 0; }
  .investigation .document-list .fixed-btns .delete-icon {
    position: absolute;
    bottom: 10px;
    right: 0px;
    top: auto; }

.investigation .have-comments {
  margin: 0 -24px 20px;
  padding: 0 19px; }

.investigation .comment-box {
  background: #fff;
  padding: 20px 0 0;
  width: 100%;
  text-align: left; }
  .investigation .comment-box textarea.form-control {
    min-height: 50px;
    margin-bottom: 0; }

.investigation .comment-history {
  max-height: 150px;
  overflow-x: hidden;
  overflow-y: auto; }
  .investigation .comment-history label {
    color: #000000;
    font-size: 14px; }
  .investigation .comment-history .comment-username {
    color: #000;
    margin-bottom: 5px; }
  .investigation .comment-history .comment-history-date {
    color: #888888;
    font-size: 12px; }
  .investigation .comment-history .comment-description {
    color: #777777; }
  .investigation .comment-history .comment-listing {
    border-bottom: 1px solid #e6e6e6;
    margin-bottom: 10px; }
    .investigation .comment-history .comment-listing p {
      margin-bottom: 10px; }
    .investigation .comment-history .comment-listing:last-child {
      border-bottom: none; }

.investigation .custom-select.not-selected {
  text-transform: capitalize; }

/*.myapp-link {
  color: red;
  text-decoration: underline; }*/
div:focus {
  outline: none; }
